/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-unused-expressions */
import React from 'react';
import {
    useNotify,
    useInput,
    DeleteButton,
    SaveButton,
    Toolbar
} from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import { Drawer, IconButton } from '@material-ui/core';
import { useForm } from 'react-final-form';

function SubentityEditForm(props) {
    const {
        children,
        onSuccessCallback,
        onCloseCallback,
        subEntity,
        basePath,
        id
    } = props;

    const notify = useNotify();
    const form = useForm();
    const {
        input: { value }
    } = useInput({ source: subEntity });

    return (
        <Drawer open onClose={() => onCloseCallback()}>
            <div className="drawer-header">
                <h3>Edit record</h3>
                <IconButton onClick={() => onCloseCallback()}>
                    <CloseIcon />
                </IconButton>
            </div>
            {React.cloneElement(children, {
                actions: null,
                id,
                basePath,
                resource: subEntity,
                onSuccess: () => {
                    form.change(subEntity, []);
                    form.change(subEntity, [...value]);
                    form.resumeValidation();
                    notify(`${subEntity} updated!`);
                    onSuccessCallback && onSuccessCallback();
                },
                toolbar: (
                    <Toolbar {...props}>
                        <SaveButton mutationMode="pessimistic" />
                        <DeleteButton
                            mutationMode="pessimistic"
                            onSuccess={() => {
                                const newValue = [...value];
                                const currentIdx = newValue.indexOf(id);
                                if (currentIdx !== -1) {
                                    newValue.splice(currentIdx, 1);
                                }
                                form.change(subEntity, [...newValue]);
                                form.resumeValidation();
                                notify(`${subEntity} deleted!`);
                                onSuccessCallback && onSuccessCallback();
                            }}
                            redirect={false}
                        />
                    </Toolbar>
                )
            })}
        </Drawer>
    );
}

export default SubentityEditForm;
