/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import {
    useMutation,
    Create,
    SimpleForm,
    useNotify,
    useRedirect
} from 'react-admin';
import {
    submitErrorsMutators,
    SubmitErrorsSpy
} from 'final-form-submit-errors';
import BasicActionsToolbar from '../BasicActionsToolbar';
import MediaInputElements from './MediaInputElements';

const stringEnum = {
    UPLOAD_API_PATH: '/media_objects',
    MEDIA_SUCCESS_MSG: 'New Media was created !',
    FILE_UPLOAD_SUCCESS_MSG: 'File succesfully uploaded !'
};

function MediaCreate(props) {
    const [mediaFile, setMediaFile] = useState(null);
    const [mutate] = useMutation();
    const notify = useNotify();
    const redirect = useRedirect();

    const customSaveWithFileUpload = async (values) => {
        try {
            const { data } = await mutate(
                {
                    type: 'create',
                    resource: props.resource,
                    payload: { data: values }
                },
                { mutationMode: 'pessimistic', returnPromise: true }
            );
            if (mediaFile) {
                const formData = new FormData();
                formData.append('file', mediaFile);
                formData.append('mediaId', data['@id']);
                notify(stringEnum.MEDIA_SUCCESS_MSG);
                fetch(
                    `${global.RA_API_ENTRYPOINT}${stringEnum.UPLOAD_API_PATH}`,
                    {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                'token'
                            )}`
                        },
                        body: formData
                    }
                )
                    .then((response) => {
                        if (response.status === 201) {
                            notify(stringEnum.FILE_UPLOAD_SUCCESS_MSG);
                            redirect('/media');
                        } else {
                            notify('File upload failed');
                        }
                    })
                    .catch((error) => {
                        notify('File upload failed', error);
                    });
            }
        } catch (error) {
            const submissionErrors = props.schemaAnalyzer.getSubmissionErrors(
                error
            );

            if (!submissionErrors) {
                notify(`Error: ${error.message}`);
                return {};
            }

            // workaround: API returns category as string, ui expects an object with property @id
            if (submissionErrors.type) {
                const type = { '@id': submissionErrors.type };
                submissionErrors.type = type;
            }

            return submissionErrors;
        }
    };

    return (
        <Create
            {...props}
            mutationMode="pessimistic"
            actions={<BasicActionsToolbar showList {...props} />}
            transform={(data) => ({
                ...data,
                type: data.type['@id']
            })}
            onSuccess={() => {}}
        >
            <SimpleForm
                save={customSaveWithFileUpload}
                mutators={{
                    ...submitErrorsMutators
                }}
            >
                <MediaInputElements
                    onFileInputChange={setMediaFile}
                    mediaFile={mediaFile}
                />
                <SubmitErrorsSpy />
            </SimpleForm>
        </Create>
    );
}

export default MediaCreate;
