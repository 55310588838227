const playerDataStructure = {
    type: { translatedKey: 'type', defaultValue: null, transform: (value) => value },
    templateversion: {
        translatedKey: 'templateversion',
        defaultValue: null,
        transform: (value) => value
    },
    contentversion: {
        translatedKey: 'contentversion',
        defaultValue: null,
        transform: (value) => value
    },
    contentsubversion: {
        translatedKey: 'contentsubversion',
        defaultValue: null,
        transform: (value) => value
    },
    name: { translatedKey: 'name', defaultValue: null, transform: (value) => value },
    slug: { translatedKey: 'slug', defaultValue: null, transform: (value) => value },
    lang: { translatedKey: 'lang', defaultValue: null, transform: (value) => value },
    video: {
        translatedKey: 'video',
        defaultValue: null,
        transform: (value) => {
            const hydraFreeValue = { ...value['hydra:member'][0] };
            // const dashFirstManifestArray = hydraFreeValue?.manifests || [];

            const manifestsArray = hydraFreeValue?.manifests || [];

            const isMpdManifest = (e = '') => e.toLowerCase().endsWith('.mpd');
            const mpdManifest = manifestsArray.find((e = '') => isMpdManifest(e));
            const restOfManifests = manifestsArray.filter((e = '') => !isMpdManifest(e));

            const dashFirstManifestArray = !mpdManifest
                ? manifestsArray
                : [mpdManifest, ...restOfManifests];

            return {
                parent: {
                    type: hydraFreeValue?.parent?.type || null,
                    slug: hydraFreeValue?.parent?.slug || null
                },
                videoType: hydraFreeValue?.videoType || null,
                nextEpisodeSlug: hydraFreeValue?.nextEpisodeSlug || null,
                manifests: dashFirstManifestArray,
                duration: hydraFreeValue?.duration || 0,
                titleEllipsis: hydraFreeValue?.titleEllipsis || '',
                titleFull: hydraFreeValue?.titleFull || '',
                titleShort: hydraFreeValue?.titleShort || '',
                chapters: hydraFreeValue?.chapters || [],
                license: {
                    geoLock: hydraFreeValue?.licence?.geoLock || [],
                    continueAllowed: hydraFreeValue?.licence?.continueAllowed || false
                },
                authToken: hydraFreeValue?.authToken || ''
            };
        }
    },
    playerUiConfig: {
        translatedKey: 'playerUiConfig',
        defaultValue: null,
        transform: (value) => {
            const hydraFreeValue = { ...value['hydra:member'][0] };
            return {
                introSegment: {
                    from: hydraFreeValue?.introSegment?.from || -1,
                    to: hydraFreeValue?.introSegment?.to || -1
                },
                creditsSegment: {
                    from: hydraFreeValue?.creditsSegment?.from || -1,
                    to: hydraFreeValue?.creditsSegment?.to || -1
                },
                posterImage: {
                    large: hydraFreeValue?.posterImage?.large || 'placeholder.jpeg',
                    medium: hydraFreeValue?.posterImage?.medium || 'placeholder.jpeg',
                    small: hydraFreeValue?.posterImage?.small || 'placeholder.jpeg'
                },
                audioAndSubtitlePopup: {
                    subtitles: hydraFreeValue?.audioAndSubtitlePopup?.subtitles || [],
                    audioTracks: hydraFreeValue?.audioAndSubtitlePopup?.audioTracks || []
                },
                videoVariants: hydraFreeValue?.videoVariants || [],
                scrubPreview: {
                    imagesPerColumn: hydraFreeValue?.scrubPreview?.imagesPerColumn || 5,
                    imagesPerRow: hydraFreeValue?.scrubPreview?.imagesPerRow || 5,
                    spritesCount: hydraFreeValue?.scrubPreview?.spritesCount || 5,
                    imageInterval: hydraFreeValue?.scrubPreview?.imageInterval || 2000,
                    filenameScheme:
                        hydraFreeValue?.scrubPreview?.filenameScheme || 'sprite-%index%.jpg',
                    sizes: [
                        {
                            size: 'small',
                            imageWidth: 180,
                            imageHeight: 102,
                            urlBase: hydraFreeValue?.scrubPreview?.sizes[1]?.urlBase || ''
                        },
                        {
                            size: 'medium',
                            imageWidth: 240,
                            imageHeight: 135,
                            urlBase: hydraFreeValue?.scrubPreview?.sizes[0]?.urlBase || ''
                        }
                    ]
                }
            };
        }
    }
};

export const playerLicence = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1cmxzIjpbIjovL2RiLWljZS12b2QuZW5kYXZhLmJlcmxpbi8iLCI6Ly94b3oub25lLyIsIjovL2RiLWljZS12b2QuZXhvemV0LmNvbS8iXSwidHlwZSI6IldlYiIsImtpZCI6Mjc0NSwiaWx2IjpmYWxzZX0.RQXgR6pqSxKPnW8zR9p3Nync6PlH4x_C4KVxTuTPggq-rcrzKqbLS1OkGtGlqb58RAxermJ4lRe9_F8nG0T1WufoEYOK1zTWmhd0hXomO4-WVLeeTr6SMlmvYun4HmcIEV1cjBPL5_lmjcMDYgkANRy0l3GXJNrReqGDKV-kGphrYokZ6cnHL2YBDiUqWzEcUcvfqK0WYPfChQpX1T7lypcy6rbK3u9DdEvpaPMLJzGRXqMFAXOTvpMOueCVdpzJgiwX_BgqPzcQKj0RR-ZnYBVqaNOcU5-YdPKFL44Ykbs_jbHDOELGp5zeLxJ7G-qt8rK_LDmPGJTygVQLvLLLQw';

export const translateApiResponse = (response) => {
    const translatedResponse = {};
    Object.keys(playerDataStructure).forEach((key) => {
        translatedResponse[playerDataStructure[key].translatedKey] =
            response[key] == null
                ? playerDataStructure[key].defaultValue
                : playerDataStructure[key].transform(response[key]);
    });
    return translatedResponse;
};
export const isSafari = () => {
    return /Safari/i.test(navigator.userAgent) && !/Chrome|Chromium/i.test(navigator.userAgent);
};

export const getPlayerConfig = async (slug) => {
    const response = await fetch(
        `${window.RA_API_ENTRYPOINT}/portal-players/${slug}`
    ).then((resp) => resp.json().then((data) => data));

    return response;
};

export const flagsDecoder = {
    de: 'de',
    'de-DE': 'de',
    en: 'en',
    'en-US': 'en',
    pl: 'pl',
    'pl-PL': 'pl',
    es: 'es',
    'es-ES': 'es',
    fr: 'fr',
    'fr-FR': 'fr',
    it: 'it',
    'it-IT': 'it'
};

const labelDecoder = {
    de: 'Deutsch',
    'de-DE': 'Deutsch',
    DE: 'Deutsch',
    en: 'Englisch',
    'en-US': 'Englisch',
    pl: 'Polnische',
    'pl-PL': 'Polnische',
    es: 'Spanisch',
    'es-ES': 'Spanisch',
    fr: 'Französisch',
    'fr-FR': 'Französisch',
    it: 'Italienisch',
    'it-IT': 'Italienisch'
};

export const generateLanguageTrackLabel = (languageTrack) => {
    if (languageTrack?.language) {
        let label = labelDecoder[languageTrack.language];

        if (languageTrack.kind) {
            if (
                !['main', 'subtitle', 'subtitles'].includes(
                    languageTrack.kind.toString().toLowerCase()
                )
            ) {
                if (languageTrack.type === 'audio') {
                    label += ' (AD)';
                } else {
                    label += ` (${languageTrack.kind})`;
                }
            }
        } else if (languageTrack?.roles.length > 0) {
            if (
                !['main', 'subtitle', 'subtitles'].includes(
                    languageTrack.roles[0].toString().toLowerCase()
                )
            ) {
                if (languageTrack.type === 'audio') {
                    label += ' (AD)';
                } else {
                    label += ` (${languageTrack.roles[0]})`;
                }
            }
        } else if (languageTrack.type === 'audio') {
            label += ' (AD)';
        } else {
            label += ' (alternative)';
        }
        return label;
    }
    return 'Aus';
};
