/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { translateApiResponse, isSafari, getPlayerConfig, playerLicence } from 'utils/player';
import '../../clpp/clpp.styles.css';
import clpp from '../../clpp/clpp';
import Select from './Select';

import './player.scss';

class Player extends React.Component {
    constructor(props) {
        super(props);
        this.videoElementRef = React.createRef();
        this.player = null;
        this.state = {
            contentDuration: null,
            currentPausedTime: null,
            isPlayerPaused: false,
            audioTracksList: [],
            subtitlesList: [],
            currentSubtitleTrack: null,
            currentAudioTrack: null,
            error: null
        };
    }

    async componentDidMount() {
        clpp.log.setLogLevel(0);

        let DRMBaseURL = '';

        switch (window.RA_API_ENTRYPOINT) {
            case 'https://api.filme-serien.iceportal.de/api':
                DRMBaseURL = 'https://filme-serien.iceportal.de';
                break;

            case 'https://vdms.red.db-ice-vod.endava.berlin/api':
                DRMBaseURL = 'https://portal.red.db-ice-vod.endava.berlin';
                break;

            default:
                DRMBaseURL = 'https://portal.testing.db-ice-vod.endava.berlin';
                break;
        }

        const drmSystem = {};
        const customData = {
            baseUrl: window.location.href.includes('localhost')
                ? 'https://portal.testing.db-ice-vod.endava.berlin'
                : DRMBaseURL,
            widevineUrl: 'widevine',
            fairplayUrl: 'fairplay',
            userId: 'userId',
            sessionId: 'sessionId',
            merchant: 'merchant'
        };

        if (!isSafari()) {
            drmSystem.preferredDrmSystem = 'com.widevine.alpha';
        }

        const config = {
            textStyle: {
                fontFamily: 'inherit',
                fontColor: '#fff',
                fontSize: '16px',
                backgroundColor: 'rgba(0,0,0,0)',
                edgeType: clpp.text.EdgeType.DROP_SHADOW,
                edgeColor: 'rgba(0,0,0,.5)'
            },
            license: playerLicence,
            autoplay: false,
            enableHtmlCue: true,
            drm: {
                env: 'DRMtodayOnboard',
                ...drmSystem,
                customData
            }
        };
        try {
            this.player = new clpp.Player(this.videoElementRef.current, config);

            this.player.use(clpp.dash.DashComponent);
            this.player.use(clpp.hls.HlsComponent);
            this.player.use(clpp.onboard.OnboardComponent);
            this.player.use(clpp.htmlcue.HtmlCueComponent);
            this.player.on(clpp.events.STATE_CHANGED, (e) => this.handleStateChange(e));

            this.player.on(clpp.events.TRACKS_ADDED, () => {
                // ALLOWED COMMENTS, do not DELETE THIS
                // eslint-disable-next-line no-console
                console.log('getTextTracks - ', this.player.getTrackManager().getTextTracks());
                // eslint-disable-next-line no-console
                console.log('getAudioTracks - ', this.player.getTrackManager().getAudioTracks());
                // eslint-disable-next-line no-console
                console.log('getVideoTracks - ', this.player.getTrackManager().getVideoTracks());

                this.setState(
                    {
                        audioTracksList: this.player.getTrackManager().getAudioTracks(),
                        subtitlesList: this.player.getTrackManager().getTextTracks(),
                        currentSubtitleTrack: this.player.getTrackManager().getTextTrack(),
                        currentAudioTrack: this.player.getTrackManager().getAudioTrack(),
                        contentDuration: this.player.getDuration()
                    },
                    () => {
                        this.props.setContentDuration(this.state.contentDuration);
                    }
                );
            });

            let slugURLPath = this.props.slug;
            if (Array.isArray(this.props.releases) && this.props.releases.length > 0) {
                const releaseId = this.props.releases[0].split('/')[this.props.releases[0].split('/').length - 1];
                if (releaseId) {
                    slugURLPath += `?preview=${releaseId}`;
                }

            }
            let playerData = await getPlayerConfig(slugURLPath);
            playerData = translateApiResponse(playerData);

            if (playerData) {
                const {
                    video: { manifests }
                } = playerData;
                try {
                    await this.player.load({
                        source: isSafari() ? manifests[1] : manifests
                    });
                } catch (error) {
                    console.log(error);
                    this.setState({
                        error: 'The player has encountered an error and is unable to load this resource.'
                    });
                }
            }
            const timeSeek = (timeInSec) => {
                this.player.seek(timeInSec);
            };

            this.props.setSeekMethod({ timeSeekMethod: timeSeek });
        } catch (error) {
            console.log(error);
            this.setState({ error: 'The player has encountered an error and could not be initialized.' });
            this.props.setPlayerHasError(true);
        }
    }

    async componentWillUnmount() {
        this.props.setPlayerHasError(false);
        if (this.player) {
            await this.player.destroy();
            this.player = null;
            delete window.player;
        }

        return null;
    }

    handleStateChange(e) {
        let paused;
        switch (e.detail.currentState) {
            case clpp.Player.State.PAUSED:
            case clpp.Player.State.IDLE:
            case clpp.Player.State.ENDED:
                paused = true;
                break;
            case clpp.Player.State.PLAYING:
                paused = false;
                break;
            default:
                break;
        }

        if (typeof paused === 'boolean') {
            this.setState({ isPlayerPaused: paused }, () => {
                this.props.setIsPlayerPaused(this.state.isPlayerPaused);

                if (this.state.isPlayerPaused) {
                    const currentPausedTime = Math.floor(this.player.getPosition());
                    this.setState({ currentPausedTime });
                    this.props.setCurrentPausedTime(currentPausedTime);
                }
            });
        }
    }

    handleSetAudioTrack(track) {
        this.setState({ currentAudioTrack: track }, () => {
            this.player.getTrackManager().setAudioTrack(track);
        });
    }

    handleSetSubtitleTrack(track) {
        this.setState({ currentSubtitleTrack: track }, () => {
            this.player.getTrackManager().setTextTrack(track);
        });
    }

    render() {
        return (
            <>
                <div
                    className="player-wrapper"
                    style={{
                        maxWidth: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    {this.state.error ? (
                        <div style={{ textAlign: 'center', margin: '20px 0' }}>{this.state.error}</div>
                    ) : (
                        <div style={{ display: 'flex', margin: '20px 0' }}>
                            <div style={{ marginRight: '30px' }}>
                                <Select
                                    value={this.state.currentAudioTrack}
                                    label="Audio"
                                    list={this.state.audioTracksList}
                                    onChange={(audioTrack) => this.handleSetAudioTrack(audioTrack)}
                                />
                            </div>
                            <div>
                                <Select
                                    value={this.state.currentSubtitleTrack}
                                    label="Subtitle"
                                    list={this.state.subtitlesList}
                                    onChange={(subtitleTrack) => this.handleSetSubtitleTrack(subtitleTrack)}
                                />
                            </div>
                        </div>
                    )}
                    <video
                        playsInline
                        webkit-playsinline="true"
                        data-testid="video"
                        ref={this.videoElementRef}
                        controls
                        preload="auto"
                    />
                </div>
            </>
        );
    }
}

export default Player;
