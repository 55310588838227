/* eslint-disable no-unused-expressions */
import React from 'react';
import { useNotify, useInput, Query, LinearProgress, Error } from 'react-admin';
import {
    List,
    ListItem,
    ListItemSecondaryAction,
    IconButton,
    Button,
    Dialog,
    Checkbox,
    ListItemText,
    Drawer
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import { useForm } from 'react-final-form';

function SubentityCreateForm(props) {
    const {
        name: subentityResource,
        source,
        basePath,
        create: SubEntityCreateModule
    } = props;
    const notify = useNotify();
    const {
        input: { value }
    } = useInput({ source });
    const form = useForm();
    const [showForm, setShowForm] = React.useState(false);
    const [showList, setShowList] = React.useState(false);
    const [checked, setChecked] = React.useState([...value]);

    const handleToggle = (val) => () => {
        const currentIndex = checked.indexOf(val);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(val);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        form.change(source, [...newChecked]);
    };

    React.useEffect(() => {
        if (!showList) {
            setChecked(value);
        }
    }, [value, showList]);

    return (
        <>
            <Button
                variant="outlined"
                onClick={() => setShowForm(true)}
                color="secondary"
                startIcon={<CreateIcon />}
            >
                Create
            </Button>
            <Button
                style={{ marginLeft: '15px' }}
                variant="outlined"
                onClick={() => setShowList(true)}
                color="secondary"
                startIcon={<AddCircleOutlineIcon />}
            >
                Add
            </Button>
            <Drawer open={showForm} onClose={() => setShowForm(false)}>
                <div className="drawer-header">
                    <h3>Create new record</h3>
                    <IconButton onClick={() => setShowForm(false)}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <SubEntityCreateModule
                    mutationMode="pessimistic"
                    name={subentityResource}
                    resource={subentityResource}
                    basePath={basePath}
                    onSuccess={({ data }) => {
                        form.change(source, [...value, data['@id']]);
                        form.resumeValidation();
                        notify(`${source} updated!`);
                        setShowForm(false);
                    }}
                    actions={null}
                />
            </Drawer>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={showList}
                onClose={() => setShowList(false)}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px'
                    }}
                >
                    <h3>Select {source} from the list</h3>
                    <IconButton onClick={() => setShowList(false)}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div style={{ overflowY: 'scroll', padding: '30px' }}>
                    <List>
                        <Query
                            type="getList"
                            resource={subentityResource}
                            payload={{
                                pagination: { page: 1, perPage: 100 },
                                sort: { field: 'id', order: 'ASC' }
                            }}
                        >
                            {({ data, loading, error }) => {
                                if (loading) {
                                    return <LinearProgress />;
                                }
                                if (error) {
                                    return <Error />;
                                }
                                return data.map((rec, idx) => {
                                    return (
                                        <ListItem
                                            style={{
                                                borderBottom:
                                                    idx === data.length - 1
                                                        ? 'none'
                                                        : '1px solid silver',
                                                padding: '7px 15px'
                                            }}
                                        >
                                            <ListItemText
                                                primary={rec.content}
                                            />
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    edge="end"
                                                    onChange={handleToggle(
                                                        rec['@id']
                                                    )}
                                                    checked={
                                                        checked.indexOf(
                                                            rec['@id']
                                                        ) !== -1
                                                    }
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                });
                            }}
                        </Query>
                    </List>
                </div>
            </Dialog>
        </>
    );
}

export default SubentityCreateForm;
