/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useRef, useContext } from 'react';
import { Button, useNotify } from 'react-admin';
import { CloudUpload, Close, Publish, Attachment } from '@material-ui/icons';
import { DialogTitle, Dialog, Chip, CircularProgress } from '@material-ui/core';
import AvailsDialog from 'components/AvailsDialog';
import { ImportPollingContext } from 'context/ImportPollingContext';
import { extractBaseUrl } from '../../utils/helpers';

const MetadataFileUploadDiaglog = (props) => {
    const { setStartPolling } = useContext(ImportPollingContext);
    const fileInputContentTypeRestriction = {
        json: '.json',
        excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };

    const fileInputRef = useRef(null);
    const notify = useNotify();
    const { open, onClose, handleResetAndRefreshPage } = props;
    const [file, setFile] = useState('');
    const [loading, setLoading] = useState(false);
    const [importResponseData, setImportResponseData] = useState({});
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [localError, setLocalError] = useState('');
    const importType = props?.importType || 'excel';

    const handleFileUpload = () => {
        const formData = new FormData();
        const releaseIdData = props.releaseId.split('/');

        formData.append('type', importType);
        formData.append('file', file);
        formData.append('releaseId', releaseIdData[releaseIdData.length - 1]);

        setLoading(true);
        fetch(`${extractBaseUrl(global.RA_API_ENTRYPOINT)}/api/import_workers`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: formData
        })
            .then((response) => response.json())
            .then((response) => {
                if (response) {
                    setLoading(false);
                    setImportResponseData(response);
                    setIsPopupOpen(true);
                    onClose(() => {
                        fileInputRef.current.value = '';
                        setFile('');
                    });
                    setStartPolling(!!response.statusId);
                } else {
                    setLoading(false);
                    setStartPolling(false);

                    notify(response.errors || response['hydra:description'] || 'Import could not be finished!');
                }
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
                setLocalError(error.message);
                setStartPolling(false);
                setLoading(false);
            });
    };

    const handleClose = () => {
        fileInputRef.current.value = '';
        onClose(() => {
            fileInputRef.current.value = '';
        });
        setLocalError('');
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };
    return (
        <>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="lg" fullWidth>
                <DialogTitle color="primary" id="simple-dialog-title" className="upload-dialog-title">
                    <Attachment style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                    Import {`${props.importType === 'excel' ? 'Metadata' : 'Pages'}`}
                </DialogTitle>
                <div
                    style={{
                        width: '90%',
                        padding: '30px',
                        position: 'relative'
                    }}
                >
                    <div style={{ display: 'flex', width: '100%' }}>
                        <Chip
                            label={(file && file.name) || 'No file selected...'}
                            onDelete={() => {
                                setFile('');
                                fileInputRef.current.value = '';
                            }}
                            color="primary"
                            variant="outlined"
                            style={{ width: '90%', marginRight: '10px' }}
                        />
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept={props.importType === 'excel' ? fileInputContentTypeRestriction.excel : fileInputContentTypeRestriction.json}
                            onChange={handleFileChange}
                            hidden
                        />
                        <Button
                            onClick={() => {
                                fileInputRef.current.click();
                            }}
                            label="Pick a file"
                            component="label"
                            style={{ width: '200px' }}
                            variant="contained"
                            disabled={loading}
                        >
                            <Publish />
                        </Button>
                    </div>
                    <div
                        style={{
                            bottom: '30px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '40px'
                        }}
                    >
                        <div style={{ color: 'red', marginRight: '15px' }}>{localError && `Error: ${localError}`}</div>
                        {file && file.name && (
                            <Button
                                variant="contained"
                                disabled={loading}
                                onClick={handleFileUpload}
                                label="Import"
                                component="label"
                                style={{ marginRight: '10px' }}
                            >
                                {(loading && (
                                    <CircularProgress
                                        size={15}
                                        style={{
                                            color: '#fff'
                                        }}
                                    />
                                )) || <CloudUpload />}
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            onClick={() => {
                                onClose();
                                setFile('');
                                setLocalError('');
                            }}
                            label="Cancel"
                            component="label"
                            disabled={loading}
                        >
                            <Close />
                        </Button>
                    </div>
                </div>
            </Dialog>
            <AvailsDialog data={importResponseData} open={isPopupOpen} onClose={() => { setIsPopupOpen(false); handleResetAndRefreshPage(); }} topic={importType === 'excel' ? 'Metadata' : 'Pages'} />
        </>
    );
};

export default MetadataFileUploadDiaglog;
