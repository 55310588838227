import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker
} from '@material-ui/pickers';

import './TimePicker.scss';

export default function TimePicker(props) {
    const { errorMessage } = props || {};
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
                <KeyboardTimePicker
                    {...props}
                    margin="normal"
                    KeyboardButtonProps={{
                        'aria-label': 'change time'
                    }}
                    error={!!errorMessage}
                />
            </Grid>
            { !!errorMessage && <span className="time-picker__error-msg">{errorMessage}</span> }
        </MuiPickersUtilsProvider>
    );
}
