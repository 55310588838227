/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { cloneElement, useState } from 'react';
import {
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Button,
    sanitizeListRestProps
} from 'react-admin';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AvailsDialog from 'components/AvailsDialog';
import FileUploadDiaglog from './FileUploadModal';
import mockData from '../../utils/mockData.json';

const VideosListActions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total
    } = useListContext();

    const [open, setOpen] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <FileUploadDiaglog
                open={open}
                onClose={(callback) => {
                    setOpen(false);
                    if (callback) callback();
                }}
            />
            <AvailsDialog data={mockData} open={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
            {/* <Button
                onClick={() => setIsPopupOpen(true)}
                label="Open Popup"
                component="label"
            >
                <ImportExportIcon />
            </Button> */}
            <Button
                onClick={() => setOpen(true)}
                label="Import"
                component="label"
            >
                <ImportExportIcon />
            </Button>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button'
                })}
            <CreateButton basePath={basePath} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
        </TopToolbar>
    );
};

export default VideosListActions;
