/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import {
    List,
    TextField,
    Datagrid,
    EditButton,
    Filter,
    TextInput,
    ReferenceField,
    SelectInput,
    ReferenceInput,
    useNotify,
    useRefresh,
    useLocale,
    useTranslate,
    usePermissions,
    Pagination
} from 'react-admin';
import { HorizontalSplit } from '@material-ui/icons';
import { Backdrop, CircularProgress, DialogTitle, Dialog, TextField as MuiTextField, Button } from '@material-ui/core';
import SSDSReturnDialog from './SSDSReturnDialog';
import CheckinCheckout from '../CheckinCheckout';

import SSDSListActions from './SSDSListActions';
import { releaseColors, ssdStatuses } from '../../constants';
import '../entities.scss';
import {
    areSSDsCheckInCheckOutButtonsEnabled,
    isEditSSDsAccessible,
    isTechnikerUser,
    isWerksMeisterUser
} from '../../utils/user';
import { cleanSSDScannerPrefix } from '../../utils/helpers';
import SSDSGanttChart from './SSDSGanttChart';

const PostFilter = (props) => {
    const locale = useLocale();
    return (
        <Filter {...props}>
            <TextInput label="serialNumber" source="serialNumber" />
            <TextInput label="comment" source="comment" />
            <SelectInput
                label="ssds.colorCode"
                source="release.colorCode"
                choices={releaseColors.map((color) => ({
                    id: color.code,
                    name: color.label
                }))}
            />
            <SelectInput
                label="Status"
                source="workflowStatus"
                choices={ssdStatuses.map((status) => ({
                    id: status.id,
                    name: status.status[locale]
                }))}
            />
            <ReferenceInput label="ssds.releaseNumber" source="release" reference="releases">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="train" source="train" reference="trainunits">
                <SelectInput optionText="tzn" />
            </ReferenceInput>
            <ReferenceInput label="location" source="deliveryCase.location" reference="locations">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Case" source="deliveryCase" reference="delivery_cases">
                <SelectInput optionText="serialNumber" />
            </ReferenceInput>
        </Filter>
    );
};

const ColorCode = ({ record, source }) => {
    return (
        <div
            style={{
                borderRadius: '15px',
                border: '1px solid silver',
                width: '30px',
                height: '30px',
                backgroundColor: record[source]?.colorCode,
                textAlign: 'center',
                margin: '0 auto'
            }}
        />
    );
};

const HealthStatus = ({ record, source }) => {
    const color = {
        0: 'green',
        1: 'yellow',
        2: 'red'
    };
    return (
        <div
            style={{
                borderRadius: '15px',
                width: '20px',
                height: '20px',
                backgroundColor: color[record[source]],
                textAlign: 'center',
                margin: '0 auto'
            }}
        />
    );
};

const WorkflowCustomField = ({ record, source }) => {
    const locale = useLocale();
    const backgroundColor = ssdStatuses.find((el) => el.id === record[source])?.backgroundColor || 'gray';
    const label = ssdStatuses.find((el) => el.id === record[source])?.status[locale] || record[source];
    return (
        <span className="workflow-custom-field" style={{ backgroundColor }}>
            {label}
        </span>
    );
};

const PostPagination = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />;

const SSDSList = (props) => {
    const serialNumberRef = React.useRef(null);
    const commentRef = React.useRef(null);
    const tznRef = React.useRef(null);
    const checkInBtnRef = React.useRef(null);
    const notify = useNotify();
    const refresh = useRefresh();
    const [releaseTimelineFilter, setReleaseTimelineFilter] = React.useState({
        filterProp: {},
        releaseType: ''
    });
    const [inputSerialNumber, setInputSerialNumber] = React.useState('');
    const [inputComment, setInputComment] = React.useState('');
    const [inputTZN, setInputTZN] = React.useState('');
    const [checkinDialogStatus, setCheckinDialogStatus] = React.useState(false);
    const [checkoutDialogStatus, setCheckoutDialogStatus] = React.useState(false);
    const [requestInProgress, setRequestInProgress] = React.useState(false);
    const translate = useTranslate();
    const { permissions: userRoles } = usePermissions();

    const doCheckin = () => {
        setRequestInProgress(true);
        fetch(`${global.RA_API_ENTRYPOINT}/s_s_ds/checkin`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                serialNumber: inputSerialNumber,
                comment: inputComment,
                tzn: inputTZN
            })
        })
            .then(({ status }) => {
                if (status === 200) {
                    setCheckinDialogStatus(false);
                    setInputSerialNumber('');
                    setInputComment('');
                    setInputTZN('');
                    notify('ssds.checkedInSuccessfully');
                    refresh();
                    setRequestInProgress(false);
                } else {
                    notify('errors.somethingWrong');
                    setRequestInProgress(false);
                }
            })
            .catch(() => {
                notify('errors.somethingWrong');
                setRequestInProgress(false);
            });
    };
    const doCheckout = () => {
        setRequestInProgress(true);
        fetch(`${global.RA_API_ENTRYPOINT}/s_s_ds/checkout`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                serialNumber: inputSerialNumber,
                comment: inputComment
            })
        })
            .then(({ status }) => {
                if (status === 200) {
                    setCheckoutDialogStatus(false);
                    setInputSerialNumber('');
                    setInputComment('');
                    notify('ssds.checkedOutSuccessfully');
                    refresh();
                    setRequestInProgress(false);
                } else {
                    notify('errors.somethingWrong');
                    setRequestInProgress(false);
                }
            })
            .catch(() => {
                notify('errors.somethingWrong');
                setRequestInProgress(false);
            });
    };
    React.useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [releaseTimelineFilter]);

    // filtering SSDs
    const workflowStatusFiltered = ssdStatuses.map((status) => status.id).filter((s) => s !== 'Productive');

    const setSanitizedSSDSerialNumber = (scannerOutput) => {
        setInputSerialNumber(cleanSSDScannerPrefix(scannerOutput));
    };

    const tab = (e, nextRef, muiInput = false) => {
        if (e.which === 13 && nextRef) {
            if (muiInput) {
                nextRef.current.querySelector('input').focus();
            } else {
                nextRef.current.focus();
            }

            e.preventDefault();
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            {areSSDsCheckInCheckOutButtonsEnabled(userRoles || []) && (
                <CheckinCheckout
                    entity="ssds"
                    onCheckinClick={() => setCheckinDialogStatus(true)}
                    onCheckoutClick={() => setCheckoutDialogStatus(true)}
                    returnDialog={<SSDSReturnDialog />}
                />
            )}
            <SSDSGanttChart />
            <List
                style={{ marginTop: '350px' }}
                className="centered-table"
                {...props}
                filter={
                    isTechnikerUser(props.permissions) || isWerksMeisterUser(props.permissions)
                        ? { workflowStatus: workflowStatusFiltered, ...releaseTimelineFilter.filterProp.filter }
                        : releaseTimelineFilter.filterProp.filter
                }
                actions={
                    <SSDSListActions
                        releaseFilter={releaseTimelineFilter}
                        clearReleaseFilter={() =>
                            setReleaseTimelineFilter({
                                filterProp: {},
                                releaseType: ''
                            })
                        }
                    />
                }
                filters={<PostFilter />}
                pagination={<PostPagination />}
            >
                <Datagrid>
                    <WorkflowCustomField source="workflowStatus" label="ssds.workflow" sortable={false} />
                    <ColorCode
                        cellClassName="text-align-center"
                        headerClassName="text-align-center"
                        source="release"
                        label="ssds.colorCode"
                        sortBy="release.colorCode"
                    />
                    <TextField source="serialNumber" label="serialNumber" />
                    <TextField source="comment" label="comment" />
                    <HealthStatus
                        cellClassName="text-align-center"
                        headerClassName="text-align-center"
                        source="healthStatus"
                        label="Status"
                        sortable={false}
                    />
                    <ReferenceField
                        link
                        label="Case"
                        source="deliveryCase"
                        reference="delivery_cases"
                        sortBy="deliveryCase.serialNumber"
                    >
                        <TextField source="serialNumber" />
                    </ReferenceField>
                    <ReferenceField
                        link
                        label="location"
                        source="deliveryCaseLocation"
                        reference="locations"
                        sortBy="deliveryCase.location.name"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                        link
                        label="ssds.releaseNumber"
                        source="release[@id]"
                        reference="releases"
                        sortBy="release.name"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField link label="train" source="train" reference="trainunits" sortBy="train.tzn">
                        <TextField source="tzn" />
                    </ReferenceField>
                    {isEditSSDsAccessible(userRoles) && <EditButton />}
                </Datagrid>
            </List>
            <Dialog
                onClose={() => setCheckinDialogStatus(false)}
                aria-labelledby="simple-dialog-title"
                open={checkinDialogStatus}
            >
                <DialogTitle color="primary" id="simple-dialog-title" className="upload-dialog-title">
                    <HorizontalSplit style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                    SSD {translate('ssds.checkin')}
                </DialogTitle>
                <div
                    style={{
                        width: '500px',
                        padding: '30px',
                        position: 'relative'
                    }}
                >
                    <div className="flex-inline flex-left">
                        <MuiTextField
                            autoFocus
                            ref={serialNumberRef}
                            onKeyPress={(e) => tab(e, commentRef, true)}
                            label={`SSD ${translate('serialNumber')}`}
                            variant="outlined"
                            size="small"
                            value={inputSerialNumber}
                            onChange={(e) => setSanitizedSSDSerialNumber(e.target.value)}
                        />
                        <MuiTextField
                            ref={commentRef}
                            onKeyPress={(e) => tab(e, tznRef, true)}
                            label={translate('comment')}
                            variant="outlined"
                            size="small"
                            value={inputComment}
                            onChange={(e) => setInputComment(e.target.value)}
                        />
                        <MuiTextField
                            ref={tznRef}
                            onKeyPress={(e) => tab(e, checkInBtnRef)}
                            style={{ width: '30%' }}
                            label="TZN"
                            variant="outlined"
                            size="small"
                            value={inputTZN}
                            onChange={(e) => setInputTZN(e.target.value)}
                        />
                    </div>
                    <div className="flex-inline flex-right" style={{ paddingTop: '10px' }}>
                        <Button
                            ref={checkInBtnRef}
                            variant="contained"
                            color="primary"
                            onClick={doCheckin}
                            disabled={!inputSerialNumber}
                        >
                            {translate('ssds.checkin')}
                        </Button>
                        <Button onClick={() => setCheckinDialogStatus(false)} variant="contained">
                            {translate('ra.action.cancel')}
                        </Button>
                    </div>
                </div>
                {requestInProgress && (
                    <Backdrop className="spinning-loader-overlay" open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </Dialog>
            <Dialog
                onClose={() => setCheckoutDialogStatus(false)}
                aria-labelledby="simple-dialog-title"
                open={checkoutDialogStatus}
            >
                <DialogTitle color="primary" id="simple-dialog-title" className="upload-dialog-title">
                    <HorizontalSplit style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                    SSD {translate('ssds.checkout')}
                </DialogTitle>
                <div
                    style={{
                        width: '500px',
                        padding: '30px',
                        position: 'relative'
                    }}
                >
                    <div className="flex-inline flex-left">
                        <MuiTextField
                            autoFocus
                            ref={serialNumberRef}
                            onKeyPress={(e) => tab(e, commentRef, true)}
                            label={`SSD ${translate('serialNumber')}`}
                            variant="outlined"
                            size="small"
                            value={inputSerialNumber}
                            onChange={(e) => setSanitizedSSDSerialNumber(e.target.value)}
                        />
                        <MuiTextField
                            ref={commentRef}
                            onKeyPress={(e) => tab(e, checkInBtnRef)}
                            label={translate('comment')}
                            variant="outlined"
                            size="small"
                            value={inputComment}
                            onChange={(e) => setInputComment(e.target.value)}
                        />
                    </div>
                    <div className="flex-inline flex-right" style={{ paddingTop: '10px' }}>
                        <Button
                            ref={checkInBtnRef}
                            variant="contained"
                            color="primary"
                            onClick={doCheckout}
                            disabled={!inputSerialNumber}
                        >
                            OK
                        </Button>
                        <Button onClick={() => setCheckoutDialogStatus(false)} variant="contained">
                            {translate('ra.action.cancel')}
                        </Button>
                    </div>
                </div>
                {requestInProgress && (
                    <Backdrop className="spinning-loader-overlay" open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </Dialog>
        </div>
    );
};

export default SSDSList;
