/* eslint-disable camelcase */
import React from 'react';
import DataTable from 'react-data-table-component';

const contentLabelDictionary = {
    topTeaserSlider: 'Top Teaser Slider',
    filters: 'Filters section',
    sorting: 'Sorting section',
    teaserLane: 'Teaserlane',
    navigation: 'Navigation',
    footer: 'Footer',
    teaserLaneSpecial: 'Teaserlane Special',
    teaserLaneShowcase: 'Teaserlane Showcase'
};

function RDB_PageContentMovieList({ data, selectedContentRow }) {
    const columns = [
        {
            name: 'Name',
            selector: (row) => row.name,
            maxWidth: '400px',
            sortable: true
        },
        {
            name: 'Type',
            selector: (row) => row.type,
            maxWidth: '30px',
            sortable: true
        },
        {
            name: 'Position',
            selector: (row) => row.position,
            maxWidth: '30px',
            sortable: true
        }
    ];

    const translateReleaseAssetsData = (originalData) => {
        const translatedData = [...originalData];
        return translatedData;
    };


    return (
        <div className="">
            {selectedContentRow && (
                <div style={{ textAlign: 'center', fontWeight: 'bold', padding: '10px' }}>
                    {selectedContentRow.name ? `${selectedContentRow.name} (${contentLabelDictionary[selectedContentRow.type]})` : contentLabelDictionary[selectedContentRow.type]}
                </div>
              )}
            <DataTable
                dense
                columns={columns}
                data={translateReleaseAssetsData(data)}
                pagination
                defaultSortFieldId={3}
            />
        </div>

    );
}

export default RDB_PageContentMovieList;
