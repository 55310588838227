/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

function RDB_MovieList({ data, onSelectedRowsChange, filters, loading, release, clearSelected }) {
    const [selectedRecord, setSelectedRecord] = useState(null);
    const columns = [
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true
        },
        {
            name: 'Category',
            selector: (row) => row.category,
            maxWidth: '30px',
            sortable: true
        },
        {
            name: 'Type',
            selector: (row) => row.type,
            maxWidth: '30px',
            sortable: true
        }
    ];

    const customSort = (rows, selector, direction) => {
        return rows.sort((rowA, rowB) => {
            const aField = selector(rowA);
            const bField = selector(rowB);

            let comparison = 0;
            if (aField === '') {
                if (direction === 'asc') {
                    comparison = 1;
                } else {
                    comparison = -1;
                }

            } else if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }

            return direction === 'desc' ? comparison * -1 : comparison;
        });
    };

    const translateReleaseAssetsData = (originalData) => {
        const translatedData = originalData.map((asset, idx) => {
            const id = idx + 1;
            const resourceId = asset.type === 'movie' ? asset.id : asset.series.id;
            const name = asset.type === 'movie' ? asset.name : asset.series.name;
            const type = asset.type === 'movie' ? 'Movie' : 'Serie';
            let displayFrom = moment().format('YYYY-MM-DD');
            if (asset.licenses && asset.licenses.length > 0) {
                displayFrom = asset.licenses[0].start;
            }
            const { slug } = asset;
            return { id, name, type, resourceId, slug, displayFrom, licenses: asset.licenses, category: asset.category };
        });
        let uniqueAssets = [];
        translatedData.forEach((asset) => {
            if (asset.type === 'Movie') {
                uniqueAssets.push({ ...asset });
            } else if (!uniqueAssets.filter((el) => el.type === 'Serie').find((el) => el.resourceId === asset.resourceId)) {
                uniqueAssets.push(asset);
            }
        });

        if (filters.movieSearchKeyword.length > 0) {
            uniqueAssets = uniqueAssets.filter((asset) => (asset.name.toLowerCase().includes(filters.movieSearchKeyword.toLowerCase()) ||
                asset.category.toLowerCase().includes(filters.movieSearchKeyword.toLowerCase())));
        }
        if (filters.selectedCategory.length > 0 && filters.selectedCategory !== '*') {
            uniqueAssets = uniqueAssets.filter((asset) => (asset.category === filters.selectedCategory));
        }
        if (filters.backupContentCheckbox) {
            uniqueAssets = uniqueAssets.filter((asset) => {
                let isBackupAsset = false;
                if (asset.licenses) {
                    asset.licenses.forEach((licence) => {
                        if ((moment(licence.start).isBefore(moment(release.validFrom)) && moment(licence.end).isBefore(moment(release.validFrom))) ||
                            (moment(licence.start).isAfter(moment(release.validTo)) && moment(licence.end).isAfter(moment(release.validTo)))) {
                            isBackupAsset = true;
                        }
                    });
                }
                return isBackupAsset;
            });
        }
        if (uniqueAssets.length < 10) {
            const generateEmptyRecord = () => {
                const highestId = uniqueAssets.reduce((acc, val) => {
                    if (val.id > acc) {
                        return val.id;
                    }
                    return acc;
                }, 0);
                return { id: highestId + 1, name: '', type: '', resourceId: '', displayFrom: '' };
            };
            const emptySlotsToFill = 10 - uniqueAssets.length;
            for (let i = 0; i < emptySlotsToFill; i++) {
                uniqueAssets.push(generateEmptyRecord());
            }
        }

        return uniqueAssets;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onRowClicked = useCallback((row) => {
        if (row.resourceId) {
            if (selectedRecord !== row.id) {
                setSelectedRecord(row.id);
                onSelectedRowsChange(row);
            } else {
                setSelectedRecord(null);
                onSelectedRowsChange(null);
            }
        }
    });

    useEffect(() => { setSelectedRecord(null); }, [clearSelected]);

    const translatedData = translateReleaseAssetsData(data);
    const conditionalRowStyles = [
        {
            when: (row) => row.id === selectedRecord,
            style: {
                backgroundColor: '#F9E79F',
                color: '#000',
                '&:hover': {
                    cursor: 'pointer'
                }
            }
        }
    ];

    return (
        <DataTable
            dense
            columns={columns}
            data={translatedData}
            pagination
            defaultSortFieldId={1}
            sortFunction={customSort}
            progressPending={loading}
            progressComponent={<div className="loader-container"><CircularProgress /></div>}
            conditionalRowStyles={conditionalRowStyles}
            onRowClicked={onRowClicked}
            highlightOnHover
            pointerOnHover
        />
    );
}

export default RDB_MovieList;
