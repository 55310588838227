/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import {
    useEditController,
    Edit,
    TabbedForm,
    FormTab,
    useNotify,
    useRedirect,
    useMutation
} from 'react-admin';
import {
    submitErrorsMutators,
    SubmitErrorsSpy
} from 'final-form-submit-errors';
import WorkflowTimeline from '../WorkflowTimeline';
import BasicActionsToolbar from '../BasicActionsToolbar';
import { videoStatuses } from '../../constants';
import VideoInputElements from './VideoInputElements';
import metadata from '../Metadata';
import SubentityCreateForm from '../SubentityCreateForm';
import SubentityListForm from '../SubentityListForm';
import MarkerTabContent from './MarkerTabContent';
import '../entities.scss';

const VideosEdit = (props) => {
    const { record } = useEditController(props);
    const [mutate] = useMutation();
    const notify = useNotify();
    const redirect = useRedirect();

    // eslint-disable-next-line consistent-return
    const save = async (values) => {
        try {
            await mutate(
                {
                    type: 'update',
                    resource: props.resource,
                    payload: {
                        data: {
                            ...values,
                            enabled: Number(values.enabled),
                            animatedImage: values?.animatedImage?.['@id'],
                            staticImage: values?.staticImage?.['@id'],
                            category: values?.category?.['@id'],
                            smallImage: values?.smallImage?.['@id'],
                            media: values?.media?.['@id']
                        },
                        id: values.id
                    }
                },
                { returnPromise: true }
            );
            notify('Video was edited !');
            redirect(`/${props.resource}`);
        } catch (error) {
            const submissionErrors = props.schemaAnalyzer.getSubmissionErrors(
                error
            );

            if (!submissionErrors) {
                notify(`Error: ${error.message}`);
                return {};
            }

            return submissionErrors;
        }
    };
    const videoHydraId = (record || { '@id': '' })['@id'];
    return (
        <Edit
            mutationMode="pessimistic"
            transform={(data) => ({
                ...data,
                category: data.category['@id'],
                smallImage: data.smallImage['@id'],
                media: data.media['@id']
            })}
            {...props}
            actions={<BasicActionsToolbar showList {...props} />}
        >
            <TabbedForm
                save={save}
                mutators={{
                    ...submitErrorsMutators
                }}
                sanitizeEmptyValues={false}
            >
                <FormTab label="Video">
                    <VideoInputElements />
                </FormTab>
                <FormTab label="Metadata">
                    <SubentityCreateForm source="metadata" {...metadata} />
                    <SubentityListForm source="metadata" {...metadata} />
                </FormTab>
                <FormTab label="Workflow">
                    <div style={{ width: '100%' }}>
                        {record && (
                            <WorkflowTimeline
                                resource="videos"
                                record={record}
                                currentStatus={record.workflowStatus}
                                allStatuses={videoStatuses}
                                lastStatus={
                                    record.nextWorkflowTransitions.length === 0
                                }
                            />
                        )}
                    </div>
                </FormTab>
                <FormTab label="Marker">
                    {record && (
                    <MarkerTabContent
                        releases={record.releases}
                        slug={record.slug}
                        videoHydraId={videoHydraId}
                        category={record.category.name}
                    />
)}
                </FormTab>
                <SubmitErrorsSpy />
            </TabbedForm>
        </Edit>
    );
};

export default VideosEdit;
