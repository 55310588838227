/* eslint-disable camelcase */
import React from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

const environmentWhitelistKeywords = ['integration', 'testing', 'stage', 'red', 'blue', 'yellow', 'green'];

function RDB_DisplayDates({ data }) {
    const previewDetailPage = (row) => {
        if (global.RA_API_ENTRYPOINT) {
            const splittedByDot = global.RA_API_ENTRYPOINT.split('.');
            if (environmentWhitelistKeywords.includes(splittedByDot[1])) {
                const previewURL = `https://portal.${splittedByDot[1]}.db-ice-vod.endava.berlin/${row.slug}?display-date=${row.start}`;
                window.open(previewURL, '_blank');
            }
        }
    };
    const columns = [
        {
            name: 'Start',
            selector: (row) => row.start,
            format: (row) => { return row.start ? moment(row.start).format('DD.MM.YYYY') : ''; }
        },
        {
            name: 'End',
            selector: (row) => row.end,
            format: (row) => { return row.end ? moment(row.end).format('DD.MM.YYYY') : ''; }
        },
        {
            name: 'Preview',
            cell: (row) => {
                return (
                    <IconButton aria-label="preview" size="small" style={{ color: 'green' }} onClick={() => previewDetailPage(row)}>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                );
            },
            maxWidth: '30px'
        }

    ];

    const translateReleaseAssetsData = (originalData) => {
        const translatedData = [...originalData || []];
        return translatedData;
    };

    return (
        <DataTable
            dense
            columns={columns}
            data={translateReleaseAssetsData(data)}
        />
    );
}

export default RDB_DisplayDates;
