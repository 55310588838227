import { useEffect, useState, useRef } from 'react';
import { Backdrop, CircularProgress, DialogTitle, Dialog, Button, TextField as MuiTextField } from '@material-ui/core';
import { Button as AdminButton, useTranslate, useNotify, useRefresh } from 'react-admin';

import { HorizontalSplit } from '@material-ui/icons';
import { cleanSSDScannerPrefix } from '../../utils/helpers';

const SSDSParkDialog = () => {
    const serialNumberRef = useRef(null);
    const commentRef = useRef(null);
    const returnBtnRef = useRef(null);
    const [show, setShow] = useState(false);
    const [serialNumber, setSerialNumber] = useState('');
    const [comment, setComment] = useState('');
    const [requestInProgress, setRequestInProgress] = useState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();

    useEffect(() => {
        if (!show) {
            setSerialNumber('');
            setComment('');
        }
    }, [show]);

    const onReturn = () => {
        setRequestInProgress(true);
        fetch(`${global.RA_API_ENTRYPOINT}/s_s_ds/return`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                serialNumber,
                comment
            })
        })
            .then(({ status }) => {
                if (status === 200) {
                    setShow(false);
                    notify('ssds.returnedSuccesfully');
                    refresh();
                    setRequestInProgress(false);
                } else {
                    notify('errors.somethingWrong');
                    setRequestInProgress(false);
                }
            })
            .catch(() => {
                notify('errors.somethingWrong');
                setRequestInProgress(false);
            });
    };

    const tab = (e, nextRef, muiInput = false) => {
        if (e.which === 13 && nextRef) {
            if (muiInput) {
                nextRef.current.querySelector('input').focus();
            } else {
                nextRef.current.focus();
            }

            e.preventDefault();
        }
    };

    return (
        <>
            <AdminButton
                className="checkin-checkout__button"
                onClick={() => setShow(true)}
                size="large"
                label="ssds.return"
                component="label"
                variant="contained"
            />
            <Dialog onClose={() => setShow(false)} aria-labelledby="simple-dialog-title" open={show}>
                <DialogTitle color="primary" id="simple-dialog-title" className="upload-dialog-title">
                    <HorizontalSplit style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                    SSD {translate('ssds.return')}
                </DialogTitle>
                <div
                    style={{
                        width: '500px',
                        padding: '30px',
                        position: 'relative'
                    }}
                >
                    <div className="flex-inline flex-left">
                        <MuiTextField
                            autoFocus
                            ref={serialNumberRef}
                            onKeyPress={(e) => tab(e, commentRef, true)}
                            label={`SSD ${translate('serialNumber')}`}
                            variant="outlined"
                            size="small"
                            value={serialNumber}
                            onChange={(e) => setSerialNumber(cleanSSDScannerPrefix(e.target.value))}
                        />
                        <MuiTextField
                            ref={commentRef}
                            onKeyPress={(e) => tab(e, returnBtnRef)}
                            label={translate('comment')}
                            variant="outlined"
                            size="small"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>
                    <div className="flex-inline flex-right" style={{ paddingTop: '10px' }}>
                        <Button
                            ref={returnBtnRef}
                            variant="contained"
                            color="primary"
                            onClick={onReturn}
                            disabled={!serialNumber}
                        >
                            OK
                        </Button>
                        <Button onClick={() => setShow(false)} variant="contained">
                            {translate('ra.action.cancel')}
                        </Button>
                    </div>
                </div>
                {requestInProgress && (
                    <Backdrop className="spinning-loader-overlay" open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </Dialog>
        </>
    );
};

export default SSDSParkDialog;
