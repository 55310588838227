import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Player from './Player';
import MarkersCrud from './MarkersCRUD';

const MarkerTabContent = ({ slug, videoHydraId, category, releases }) => {
    const [currentPausedTime, setCurrentPausedTime] = useState(0);
    const [isPlayerPaused, setIsPlayerPaused] = useState(true);
    const [{ timeSeekMethod }, setSeekMethod] = useState({});
    const [playerHasError, setPlayerHasError] = useState(false);
    const [contentDuration, setContentDuration] = useState(null);

    return (
        <>
            <div>
                <Player
                    slug={slug}
                    setCurrentPausedTime={setCurrentPausedTime}
                    setIsPlayerPaused={setIsPlayerPaused}
                    setSeekMethod={setSeekMethod}
                    setPlayerHasError={setPlayerHasError}
                    setContentDuration={setContentDuration}
                    releases={releases}
                />
            </div>
            <MarkersCrud
                videoHydraId={videoHydraId}
                seekMethod={timeSeekMethod}
                currentTime={currentPausedTime}
                isPlayerPaused={isPlayerPaused}
                playerHasError={playerHasError}
                contentDuration={contentDuration}
                category={category}
            />
        </>
    );
};

MarkerTabContent.defaultProps = {
    videoHydraId: '',
    category: ''
};

MarkerTabContent.propTypes = {
    slug: PropTypes.string.isRequired,
    videoHydraId: PropTypes.string,
    category: PropTypes.string,
    releases: PropTypes.oneOfType(PropTypes.array, null).isRequired
};

export default MarkerTabContent;
