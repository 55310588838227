/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    AccessTime as AccessTimeIcon,
    Save as SaveIcon,
    Edit as EditIcon,
    Close as CloseIcon
} from '@material-ui/icons';
import { useTranslate } from 'ra-core';
import {
    Chip,
    TableBody,
    Table,
    IconButton,
    TextField,
    Fade,
    Backdrop,
    Modal,
    CircularProgress,
    TableCell,
    Button,
    Paper,
    Snackbar,
    TableRow,
    TableHead,
    TableContainer
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { v4 as uuidv4 } from 'uuid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import './MarkersCRUD.scss';
import { createMark, deleteMark, editMark, getMarks } from '../../utils/marksServices';
import TimePicker from '../../components/TimePicker';
import {
    compareMarkersByStartTime,
    runMarkersCrudValidations,
    seconds2Date,
    seconds2TimeString,
    timeFromDateTime2Seconds
} from '../../utils/markers';

const StyledTableCell = withStyles(() => ({
    head: {},
    body: {
        padding: '4px'
    }
}))(TableCell);

const markType2ChipLabelDictionary = {
    intro: 'Intro End',
    chapter: 'Chapter',
    credit: 'Credits Start'
};

const markType2ChipLabel = (markType) => markType2ChipLabelDictionary[markType] || '';

const StyledTableRow = withStyles(() => ({
    root: {}
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: 'white',
        border: '1px solid #999',
        height: '400px',
        width: '40%',
        boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.1)',
        padding: '20px'
    },
    backdrop: {
        zIndex: 999,
        color: '#fff'
    }
});

// always need al markers for a video, they cannot be more than 999
// this is how the API works
const MARKS_PER_PAGE = 999;
const MARKS_PAGE_NO = 1;

const MarkersCrud = ({ currentTime, isPlayerPaused, seekMethod, videoHydraId, playerHasError, contentDuration, category }) => {
    const currentDateTime = seconds2Date(currentTime);
    const translate = useTranslate();
    const classes = useStyles();
    const [marks, setMarks] = useState([]);
    const [isCreateMarksModalOpen, setIsCreateMarksModalOpen] = useState(false);
    const [isEditMarksModalOpen, setIsEditMarksModalOpen] = useState(false);
    const [targetedMarkType, setTargetedMarkType] = useState('intro');
    const [targetedMarkName, setTargetedMarkName] = useState('');
    const [targetedMarkId, setTargetedMarkId] = useState('');
    const [startTime, setStartTime] = useState(currentDateTime);
    const [endTime, setEndTime] = useState(currentDateTime);
    const [startTimeErrorMessage, setStartTimeErrorMessage] = useState('');
    const [endTimeErrorMessage, setEndTimeErrorMessage] = useState('');
    const [targetedMarkNameErrorMessage, setTargetedMarkNameErrorMessage] = useState('');
    const [isWritingMarkInProgress, setIsWritingMarkInProgress] = useState(false);
    const [isWriteMarkSuccessMessageVisible, setIsWriteMarkSuccessMessageVisible] = useState(false);
    const [isWriteMarkFailMessageVisible, setIsWriteMarkFailMessageVisible] = useState(false);
    const [createMarkFailErrorCode, setCreateMarkFailErrorCode] = useState('');
    const [isDeleteMarkSuccessMessageVisible, setIsDeleteMarkSuccessMessageVisible] = useState(false);
    const [isDeleteMarkFailMessageVisible, setIsDeleteMarkFailMessageVisible] = useState(false);
    const [deleteMarkFailErrorCode, setDeleteMarkFailErrorCode] = useState('');
    const [isPageSpinnerVisible, setIsPageSpinnerVisible] = useState(false);
    const [isNameFieldTouched, setIsNameFieldTouched] = useState(false);
    const [isStartTimeFieldTouched, setIsStartTimeFieldTouched] = useState(false);
    const [isEndTimeFieldTouched, setIsEndTimeFieldTouched] = useState(false);


    const onGetMarksSuccess = (fetchedMarks) => {
        setMarks(
            fetchedMarks['hydra:member']
                .filter((e) => e.video === videoHydraId)
                .map((e) => ({ ...e, chip: { label: markType2ChipLabel(e.type), type: e.type } }))
        );
        setIsPageSpinnerVisible(false);
    };

    const onGetMarksError = () => {
        setIsPageSpinnerVisible(false);
    };

    const onCreateMarkSuccess = () => {
        setIsWritingMarkInProgress(false);
        setIsWriteMarkSuccessMessageVisible(true);
    };

    const onCreateMarkError = ({ status }) => {
        setIsWritingMarkInProgress(false);
        setCreateMarkFailErrorCode(status);
        setIsWriteMarkFailMessageVisible(true);
    };

    const onDeleteMarkSuccess = () => {
        setIsPageSpinnerVisible(false);
        setIsDeleteMarkSuccessMessageVisible(true);
        setIsPageSpinnerVisible(true);
        getMarks(MARKS_PAGE_NO, MARKS_PER_PAGE, videoHydraId, onGetMarksSuccess, onGetMarksError);
    };

    const onDeleteMarkError = ({ status }) => {
        setDeleteMarkFailErrorCode(status);
        setIsDeleteMarkFailMessageVisible(true);
        setIsPageSpinnerVisible(false);
    };

    const onSubmitMarkButtonClick = () => {
        const markData = {
            name: targetedMarkName,
            type: targetedMarkType,
            video: videoHydraId,
            // eslint-disable-next-line radix
            timeStart: timeFromDateTime2Seconds(startTime),
            // eslint-disable-next-line radix
            timeEnd: timeFromDateTime2Seconds(endTime)
        };
        setIsWritingMarkInProgress(true);
        if (isCreateMarksModalOpen) {
            createMark(markData, onCreateMarkSuccess, onCreateMarkError);
            return;
        }
        if (isEditMarksModalOpen) {
            editMark(targetedMarkId, markData, onCreateMarkSuccess, onCreateMarkError);
        }
    };

    const resetModalData = () => {
        setTargetedMarkType('');
        setTargetedMarkName('');
        setTargetedMarkId('');
        setStartTime(currentDateTime);
        setEndTime(currentDateTime);
        setIsWriteMarkSuccessMessageVisible(false);
        setIsWriteMarkFailMessageVisible(false);
        setIsNameFieldTouched(false);
        setIsStartTimeFieldTouched(false);
        setIsEndTimeFieldTouched(false);
    };

    const closeModals = () => {
        setIsCreateMarksModalOpen(false);
        setIsEditMarksModalOpen(false);
    };

    const reFetchMarksIfUpdateSuccess = () => {
        if (isWriteMarkSuccessMessageVisible) {
            setIsPageSpinnerVisible(true);
            getMarks(MARKS_PAGE_NO, MARKS_PER_PAGE, videoHydraId, onGetMarksSuccess, onGetMarksError);
        }
    };

    const onModalClose = () => {
        closeModals();
        reFetchMarksIfUpdateSuccess();
        resetModalData();
    };

    useEffect(() => {
        setIsPageSpinnerVisible(true);
        getMarks(MARKS_PAGE_NO, MARKS_PER_PAGE, videoHydraId, onGetMarksSuccess, onGetMarksError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setStartTime(currentDateTime);
        setEndTime(currentDateTime);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTime]);

    // form validation useEffect
    useEffect(() => {
        const { startTimeErrorMsg, endTimeErrorMsg, nameErrorMessage } = runMarkersCrudValidations(
            targetedMarkType,
            targetedMarkName,
            startTime,
            endTime,
            seconds2Date(contentDuration)
        );

        setStartTimeErrorMessage(startTimeErrorMsg);
        setEndTimeErrorMessage(endTimeErrorMsg);
        setTargetedMarkNameErrorMessage(nameErrorMessage);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime, endTime, targetedMarkName, targetedMarkType, contentDuration]);

    return (
        <div className="markers-crud">
            <div className="markers-crud__top-btn-container">
                <Button variant="outlined" color="primary" startIcon={<AddIcon />} disabled>
                    {translate('markers.copyFromEpisode')}
                </Button>
                <div style={{ flexGrow: 1 }} />
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    disabled={!isPlayerPaused || playerHasError}
                    onClick={() => {
                        setTargetedMarkType('chapter');
                        setIsCreateMarksModalOpen(true);
                    }}
                >
                    {translate('markers.chapter')}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    classes={{ root: 'markers-crud__ml-button' }}
                    disabled={
                        !isPlayerPaused ||
                        playerHasError ||
                        marks.some((mark) => mark.type === 'intro') ||
                        category === 'CINEMA'
                    }
                    onClick={() => {
                        setTargetedMarkType('intro');
                        setIsCreateMarksModalOpen(true);
                    }}
                >
                    {translate('markers.intro')}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    classes={{ root: 'markers-crud__ml-button' }}
                    disabled={!isPlayerPaused || playerHasError || marks.some((mark) => mark.type === 'credit')}
                    onClick={() => {
                        setTargetedMarkType('credit');
                        setIsCreateMarksModalOpen(true);
                    }}
                >
                    {translate('markers.credits')}
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Time Start</StyledTableCell>
                            <StyledTableCell>Time End</StyledTableCell>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell> </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {marks.sort(compareMarkersByStartTime).map((mark) => (
                            <StyledTableRow key={uuidv4()}>
                                <StyledTableCell component="th" scope="row">
                                    <Chip
                                        label={mark.chip.label}
                                        classes={{
                                            root: `markers-crud__chip markers-crud__chip--${mark.chip.type}`
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>{seconds2TimeString(mark.timeStart)}</StyledTableCell>
                                <StyledTableCell>{seconds2TimeString(mark.timeEnd)}</StyledTableCell>
                                <StyledTableCell>{mark.name}</StyledTableCell>
                                <StyledTableCell align="right">
                                    <IconButton
                                        color="secondary"
                                        aria-label="close modal"
                                        disabled={!isPlayerPaused || playerHasError}
                                        onClick={() => seekMethod(mark.timeStart)}
                                    >
                                        <AccessTimeIcon />
                                    </IconButton>
                                    <IconButton
                                        color="secondary"
                                        classes={{ root: 'markers-crud__ml-button' }}
                                        disabled={!isPlayerPaused || playerHasError || !contentDuration}
                                        onClick={() => {
                                            setTargetedMarkType(mark.type);
                                            setTargetedMarkName(mark.name);
                                            setTargetedMarkId(mark['@id']);
                                            setStartTime(seconds2Date(mark.timeStart));
                                            setEndTime(seconds2Date(mark.timeEnd));
                                            setIsEditMarksModalOpen(true);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        color="secondary"
                                        classes={{ root: 'markers-crud__ml-button' }}
                                        disabled={!isPlayerPaused || playerHasError}
                                        onClick={() => {
                                            setIsPageSpinnerVisible(true);
                                            deleteMark(mark['@id'], onDeleteMarkSuccess, onDeleteMarkError);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={isCreateMarksModalOpen || isEditMarksModalOpen}
                onClose={onModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={isCreateMarksModalOpen || isEditMarksModalOpen}>
                    <div className={`${classes.paper} markers-crud__modal-paper`}>
                        <div className="markers-crud__close-btn-container">
                            <h3>{(targetedMarkType || '').toUpperCase()}</h3>
                            <IconButton
                                color="secondary"
                                aria-label="close modal"
                                onClick={onModalClose}
                                disabled={isWritingMarkInProgress}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className="markers-crud__input-container">
                            <TextField
                                classes={{ root: 'markers-crud__input-container__input' }}
                                id="name"
                                label="Name"
                                value={targetedMarkName}
                                disabled={isWritingMarkInProgress || isWriteMarkSuccessMessageVisible}
                                error={!!targetedMarkNameErrorMessage}
                                helperText={targetedMarkNameErrorMessage}
                                onChange={({ target: { value } }) => {
                                    setTargetedMarkName(value);
                                }}
                                onBlur={() => setIsNameFieldTouched(true)}
                            />
                        </div>
                        <div className="markers-crud__input-container ">
                            <TimePicker
                                classes={{ root: 'markers-crud__input-container__input' }}
                                id="timeStart"
                                label="Time start"
                                views={['hours', 'minutes', 'seconds']}
                                format="HH:mm:ss"
                                ampm={false}
                                value={startTime}
                                maxTime={seconds2Date(contentDuration)}
                                error={!!startTimeErrorMessage}
                                errorMessage={startTimeErrorMessage}
                                disabled={isWritingMarkInProgress || isWriteMarkSuccessMessageVisible}
                                onChange={(value) => {
                                    setStartTime(value);
                                }}
                                onClose={() => setIsStartTimeFieldTouched(true)}
                            />
                        </div>
                        {/* {targetedMarkType !== 'chapter' && ( */}
                        <div className="markers-crud__input-container ">
                            <TimePicker
                                classes={{ root: 'markers-crud__input-container__input' }}
                                id="timeEnd"
                                label="Time end"
                                variant="outlined"
                                views={['hours', 'minutes', 'seconds']}
                                format="HH:mm:ss"
                                ampm={false}
                                value={endTime}
                                maxTime={seconds2Date(contentDuration)}
                                minTime={seconds2Date(startTime)}
                                error={!!endTimeErrorMessage}
                                errorMessage={endTimeErrorMessage}
                                disabled={isWritingMarkInProgress || isWriteMarkSuccessMessageVisible}
                                onChange={(value) => {
                                    setEndTime(value);
                                }}
                                onClose={() => setIsEndTimeFieldTouched(true)}
                            />
                        </div>
                        {/* )} */}
                        <div className="markers-crud__modal-feedback-container ">
                            {isWritingMarkInProgress && <CircularProgress />}
                            {isWriteMarkSuccessMessageVisible && isCreateMarksModalOpen && (
                                <Alert severity="success">Mark created successfully!</Alert>
                            )}
                            {isWriteMarkFailMessageVisible && isCreateMarksModalOpen && (
                                <Alert severity="error">Failed to create mark! Code: {createMarkFailErrorCode}</Alert>
                            )}
                            {isWriteMarkSuccessMessageVisible && isEditMarksModalOpen && (
                                <Alert severity="success">Mark updated successfully!</Alert>
                            )}
                            {isWriteMarkFailMessageVisible && isEditMarksModalOpen && (
                                <Alert severity="error">Failed to update mark! Code: {createMarkFailErrorCode}</Alert>
                            )}
                        </div>
                        <div className="markers-crud__confirm-btn-container">
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<SaveIcon />}
                                onClick={onSubmitMarkButtonClick}
                                disabled={
                                    isWritingMarkInProgress ||
                                    isWriteMarkSuccessMessageVisible ||
                                    startTimeErrorMessage ||
                                    endTimeErrorMessage ||
                                    targetedMarkNameErrorMessage ||
                                    targetedMarkName === '' ||
                                    (targetedMarkType !== 'chapter' && startTime >= endTime)
                                }
                            >
                                {translate('markers.save')}
                            </Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Backdrop className={classes.backdrop} open={isPageSpinnerVisible}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={isDeleteMarkSuccessMessageVisible}
                autoHideDuration={6000}
                onClose={() => {
                    setIsDeleteMarkSuccessMessageVisible(false);
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => {
                        setIsDeleteMarkSuccessMessageVisible(false);
                    }}
                    severity="success"
                >
                    Mark deleted successfuly!
                </Alert>
            </Snackbar>
            <Snackbar
                open={isDeleteMarkFailMessageVisible}
                autoHideDuration={6000}
                onClose={() => {
                    setIsDeleteMarkFailMessageVisible(false);
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => {
                        setIsDeleteMarkFailMessageVisible(false);
                    }}
                    severity="error"
                >
                    Failed to delete mark! Code: {deleteMarkFailErrorCode}
                </Alert>
            </Snackbar>
        </div>
    );
};

MarkersCrud.defaultProps = {
    currentTime: 0,
    isPlayerPaused: false,
    seekMethod: () => { },
    videoHydraId: '',
    category: '',
    playerHasError: false,
    contentDuration: 0
};

MarkersCrud.propTypes = {
    currentTime: PropTypes.number,
    isPlayerPaused: PropTypes.bool,
    seekMethod: PropTypes.func,
    videoHydraId: PropTypes.string,
    playerHasError: PropTypes.bool,
    contentDuration: PropTypes.number,
    category: PropTypes.string
};

export default MarkersCrud;
