/* eslint-disable no-alert */
/* eslint-disable no-irregular-whitespace */
import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Episode from './Episode';
import { videoStatuses } from '../../constants';

function EditSeasons() {
    const [initialData, setInitialData] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { id: seasonId = null } = useParams();

    const getSeason = (id) => {
        setLoading(true);
        fetch(`${global.RA_API_ENTRYPOINT}/avails/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((jsonResponse) => {
                setLoading(false);
                setData(JSON.parse(JSON.stringify(jsonResponse)));
                setInitialData(JSON.parse(JSON.stringify(jsonResponse)));
            });
    };

    useEffect(() => {
        if (seasonId) {
            getSeason(seasonId);
        }
    }, [seasonId]);

    const setSeasonData = (key, value) => {
        const newSeasonData = { ...data };
        newSeasonData[key] = value;
        setData(newSeasonData);
    };

    const setEpisodeData = (key, value, index) => {
        const newSeasonData = { ...data };
        newSeasonData.episodes[index][key] = value;
        setData(newSeasonData);
    };

    const handleSaveSeason = () => {
        const payload = {
            seriesEpisodeNumber: data.episode
        };

        fetch(`${global.RA_API_ENTRYPOINT}/seasons​/${seasonId}​/complete`, {
            method: 'POST',
            data: payload
        });
    };
    const handleRollbackSeason = () => {
        alert('TODO handleRollbackSeason');
    };

    const isSeasonPristine = () => {
        return JSON.stringify(data) === JSON.stringify(initialData);
    };

    const isEpisodePristine = (episodeIndex) => {
        return JSON.stringify(data.episodes[episodeIndex]) === JSON.stringify(initialData.episodes[episodeIndex]);
    };

    const setWorkflowStatusData = (workflowStatusValue) => {
        const workflowStatusDetails = videoStatuses.find((status) => status.id === workflowStatusValue);
        const workflowStatusText = workflowStatusDetails?.status || workflowStatusValue;
        const workflowStatusColor = workflowStatusDetails?.backgroundColor || '#fff';

        return { workflowStatusText, workflowStatusColor };
    };

    return (
        <Box style={{ position: 'relative' }}>
            {loading && (
                <div className="loading-spinner">
                    <CircularProgress />
                </div>
            )}
            <Paper className="episodes" style={{ padding: '15px' }}>
                <h3>EDIT SEASON</h3>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '50%', display: 'inline-flex', gap: '10px' }}>
                        <TextField
                            value={data?.name || ''}
                            onChange={({ target: { value } }) => {
                                setSeasonData('name', value);
                            }}
                            style={{ width: '100%' }}
                            id="standard-basic"
                            label="Season Title"
                            variant="standard"
                        />
                        <TextField
                            value={data?.originalTitle || ''}
                            onChange={({ target: { value } }) => {
                                setSeasonData('originalTitle', value);
                            }}
                            style={{ width: '100%' }}
                            id="standard-basic"
                            label="Original Title"
                            variant="standard"
                        />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <span style={{ fontWeight: 'bold' }}>Revenue/$</span>
                        <br /> {data?.revenue || '-'}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <span style={{ fontWeight: 'bold' }}>Workflow Status:</span>
                        <br />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ width: '10px', height: '10px', border: '1px solid #eee', marginRight: '5px', borderRadius: '10px', backgroundColor: setWorkflowStatusData(data?.workflowStatus).workflowStatusColor }}/>
                            <span style={{ textTransform: 'capitalize' }}>{setWorkflowStatusData(data?.workflowStatus).workflowStatusText}</span>
                        </div>
                    </div>
                </div>
                <div className="episodes">
                    <div style={{ padding: '60px 0 30px', display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold' }}>EPISODES</span>
                        <span style={{ fontSize: '14px', color: 'gray' }}>Amount of episodes: {data?.episodes?.length || '0'}</span>
                    </div>
                    <div className="episodes__list">
                        <div className="episodes__list__table-header" style={{ display: 'flex' }}>
                            <div style={{ flexBasis: '10%' }} />
                            <div style={{ flexBasis: '10%' }}>Nr.</div>
                            <div style={{ flexBasis: '30%' }}>Episode Title</div>
                            <div style={{ flexBasis: '30%' }}>Original Title</div>
                            <div style={{ flexBasis: '20%' }}>Workflow Status</div>
                            <div style={{ flexBasis: '10%' }} />
                        </div>
                        {data && initialData && Array.isArray(data.episodes) ? (
                            data.episodes.map((episode, idx) => {
                                return <Episode index={idx} data={episode} pristine={isEpisodePristine(idx)} setEpisodeData={setEpisodeData} />;
                            })
                        ) : (
                            <div style={{ textAlign: 'center', padding: '10px' }}>No episodes available</div>
                        )}
                    </div>
                </div>
                <div style={{ display: 'inline-flex', gap: '10px', justifyContent: 'flex-end', width: '100%', marginTop: '40px' }}>
                    <Button variant="outlined" onClick={handleRollbackSeason} disabled={isSeasonPristine()}>
                        Cancel changes
                    </Button>
                    <Button variant="contained" onClick={handleSaveSeason} color="primary" disableElevation disabled={isSeasonPristine()}>
                        Save
                    </Button>
                </div>
            </Paper>
        </Box>
    );
}

export default EditSeasons;
