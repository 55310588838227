/* eslint-disable camelcase */
import React from 'react';
import DataTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';

function RDB_EpisodesList({ data, loading }) {
    const columns = [
        {
            name: 'Name',
            selector: (row) => row.name
        },
        {
            name: 'Order',
            selector: (row) => row.episodeOrder,
            maxWidth: '30px'
        }
    ];

    const translateReleaseAssetsData = (originalData) => {
        const translatedData = [...originalData || []];
        return translatedData;
    };

    return (
        <DataTable
            dense
            columns={columns}
            data={translateReleaseAssetsData(data)}
            pagination
            paginationComponentOptions={{
                noRowsPerPage: true, selectAllRowsItem: false
            }}
            paginationPerPage={6}
            paginationRowsPerPageOptions={[6, 10, 15, 20, 25, 30]}
            progressPending={loading}
            progressComponent={<div className="loader-container"><CircularProgress /></div>}
        />
    );
}

export default RDB_EpisodesList;
