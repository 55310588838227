/* eslint-disable no-console */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState, useRef } from 'react';
import {
    List,
    TextField,
    Datagrid,
    EditButton,
    Filter,
    TextInput,
    ReferenceField,
    SelectInput,
    ReferenceInput,
    useRefresh,
    useNotify,
    useDataProvider,
    useTranslate,
    useLocale,
    usePermissions
} from 'react-admin';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { LockOpen, HorizontalSplit } from '@material-ui/icons';
import InputLabel from '@material-ui/core/InputLabel';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Backdrop, CircularProgress, DialogTitle, Dialog, TextField as MuiTextField, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormHelperText from '@material-ui/core/FormHelperText';

import CasesListActions from './CasesListActions';
import { caseStatuses } from '../../constants';
import '../entities.scss';
import CheckinCheckout from '../CheckinCheckout';
import {
    areCasesCheckInCheckOutButtonsEnabled,
    isEditCasesAccessible,
    isDeleteCasesAccessible,
    isEditLocksAccessible,
    isWerksMeisterUser
} from '../../utils/user';
import { cleanSSDScannerPrefix, hasDuplicates } from '../../utils/helpers';
import SSDSGanttChart from '../SSDS/SSDSGanttChart';

const PostFilter = (props) => {
    const locale = useLocale();
    return (
        <Filter {...props}>
            <TextInput label="serialNumber" source="serialNumber" />
            <TextInput label="comment" source="comment" />
            <SelectInput
                label="Status"
                source="workflowStatus"
                choices={caseStatuses.map((status) => ({
                    id: status.id,
                    name: status.status[locale]
                }))}
            />
            <ReferenceInput label="location" source="location" reference="locations">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="SSD" source="ssds" reference="s_s_ds">
                <SelectInput optionText="serialNumber" />
            </ReferenceInput>
        </Filter>
    );
};

const WorkflowCustomField = ({ record, source }) => {
    const locale = useLocale();
    const backgroundColor = caseStatuses.find((el) => el.id === record[source])?.backgroundColor || 'gray';
    const label = caseStatuses.find((el) => el.id === record[source])?.status[locale] || record[source];
    return (
        <span className="workflow-custom-field" style={{ backgroundColor }}>
            {label}
        </span>
    );
};

const CasesList = (props) => {
    const validateInputsBtnRef = useRef();
    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const { permissions: userRoles } = usePermissions();
    const [requestInProgress, setRequestInProgress] = React.useState(false);
    const [checkinCheckoutInputSerialNumber, setCheckinCheckoutInputSerialNumber] = React.useState('');
    const [checkinCheckoutInputComment, setCheckinCheckoutInputComment] = React.useState('');
    const [caseLockCodes, setCaseLockCode] = React.useState({
        lock1: null,
        lock2: null
    });
    const [checkedCaseDetails, setCheckedCaseDetails] = React.useState({});
    const [SSDInputsFromCheckedCase, setSSDInputsFromCheckedCase] = React.useState([]);

    const [releaseTimelineFilter, setReleaseTimelineFilter] = useState({
        filterProp: {},
        releaseType: ''
    });
    const [ssdsFilter, setSsdsFilter] = useState([]);
    // MODALS
    const [showModalCheckin, setShowModalCheckin] = React.useState(false);
    const [showModalCheckout, setShowModalCheckout] = React.useState(false);
    const [showModalLockCodes, setShowModalLockCodes] = React.useState(false);
    const [showModalSSDConfirmInputs, setShowModalSSDConfirmInputs] = React.useState(false);
    const [showModalSSDFinalStatusList, setShowModalSSDFinalStatusList] = React.useState(false);

    const additionalProps = {};

    if (!isDeleteCasesAccessible(userRoles)) {
        additionalProps.bulkActionButtons = false;
    }

    useEffect(() => {
        if (!releaseTimelineFilter.filterProp.filter) {
            setSsdsFilter([]);
            return;
        }
        const filter = {
            release: releaseTimelineFilter.filterProp.filter.release
        };
        dataProvider
            .getList('s_s_ds', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'name' },
                filter
            })
            .then(({ data }) => {
                if (data.length > 0) {
                    setSsdsFilter(data.map((el) => el['@id']));
                } else {
                    /**
                     * If there's no ssd asociated with the release, we have to set the SSD filter
                     * to an array that cannot be found
                     * so the list will be empty
                     */
                    setSsdsFilter(['unfindable_ssd_id']);
                }
            });
    }, [releaseTimelineFilter, dataProvider]);

    const doCheckin = () => {
        setRequestInProgress(true);
        fetch(`${global.RA_API_ENTRYPOINT}/delivery_cases/checkin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                serialNumber: checkinCheckoutInputSerialNumber,
                comment: checkinCheckoutInputComment
            })
        })
            .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
            .then((response) => {
                if (response.status === 200) {
                    if (response.body && (response.body.lock1 || response.body.lock2)) {
                        setCaseLockCode({
                            lock1: response?.body?.lock1 || null,
                            lock2: response?.body?.lock2 || null
                        });
                        setShowModalLockCodes(true);
                    }
                    setShowModalCheckin(false);
                    setCheckinCheckoutInputComment('');
                    notify('Case checked in succesfuly!');
                    refresh();
                    setRequestInProgress(false);
                } else if (response.status === 400) {
                    notify(response?.body?.message);
                    setRequestInProgress(false);
                } else {
                    notify('errors.somethingWrong');
                    setRequestInProgress(false);
                }
            })
            .catch(() => {
                setRequestInProgress(false);
            });
    };

    const doCheckout = () => {
        setRequestInProgress(true);
        fetch(`${global.RA_API_ENTRYPOINT}/delivery_cases/checkout`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                serialNumber: checkinCheckoutInputSerialNumber,
                comment: checkinCheckoutInputComment
            })
        })
            .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
            .then((response) => {
                if (response.status === 200) {
                    setShowModalCheckout(false);
                    setCheckinCheckoutInputSerialNumber('');
                    setCheckinCheckoutInputComment('');
                    notify('Case checked out succesfuly!');
                    refresh();
                    setRequestInProgress(false);
                } else if (response.status === 400) {
                    notify(response?.body?.message);
                    setRequestInProgress(false);
                } else {
                    notify('errors.somethingWrong');
                    setRequestInProgress(false);
                }
            })
            .catch(() => {
                notify('errors.somethingWrong');
                setRequestInProgress(false);
            });
    };

    const getCheckedCaseDetails = (createSSDInputsState = true) => {
        dataProvider
            .getList('delivery_cases', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'name' },
                filter: { serialNumber: checkinCheckoutInputSerialNumber }
            })
            .then(({ data }) => {
                setCheckedCaseDetails(data[0]);
                if (createSSDInputsState && data[0].ssds) {
                    const SSDInputs = [];
                    data[0].ssds.forEach((ssd, idx) => {
                        SSDInputs.push({
                            id: idx,
                            value: '',
                            valid: false,
                            canBeAdded: false
                        });
                    });
                    setSSDInputsFromCheckedCase(SSDInputs);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        if (showModalSSDConfirmInputs && checkinCheckoutInputSerialNumber) {
            getCheckedCaseDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModalSSDConfirmInputs, checkinCheckoutInputSerialNumber]);

    const updateSSDInputsFromCheckedCase = (e, id) => {
        const newSSDInputsFromCheckedCase = [...SSDInputsFromCheckedCase];
        const inputIndex = newSSDInputsFromCheckedCase.findIndex((input) => input.id === id);
        if (inputIndex !== -1) {
            newSSDInputsFromCheckedCase[inputIndex].value = cleanSSDScannerPrefix(e.target.value);
        }
        setSSDInputsFromCheckedCase(newSSDInputsFromCheckedCase);
    };

    const validateSSDInTheCase = async () => {
        setRequestInProgress(true);
        const newSSDInputsFromCheckedCase = [...SSDInputsFromCheckedCase];

        for (let i = 0; i < SSDInputsFromCheckedCase.length; i++) {
            if (checkedCaseDetails.ssds.find((ssd) => ssd.serialNumber === SSDInputsFromCheckedCase[i].value)) {
                newSSDInputsFromCheckedCase[i].valid = true;
                newSSDInputsFromCheckedCase[i].canBeAdded = false;
            } else if (SSDInputsFromCheckedCase[i].value) {
                // eslint-disable-next-line no-await-in-loop
                const { data } = await dataProvider.getList('s_s_ds', {
                    pagination: { page: 1, perPage: 999 },
                    sort: { field: 'name' },
                    filter: {
                        serialNumber: SSDInputsFromCheckedCase[i].value
                    }
                });
                newSSDInputsFromCheckedCase[i].valid = false;
                if (data.length === 1 && !data.deliveryCase) {
                    newSSDInputsFromCheckedCase[i].canBeAdded = true;
                } else {
                    newSSDInputsFromCheckedCase[i].canBeAdded = false;
                }
            } else {
                newSSDInputsFromCheckedCase[i].valid = false;
                newSSDInputsFromCheckedCase[i].canBeAdded = false;
            }
        }

        while (
            newSSDInputsFromCheckedCase.length > checkedCaseDetails.ssds.length &&
            newSSDInputsFromCheckedCase.find((input) => input.value === '')
        ) {
            if (newSSDInputsFromCheckedCase[newSSDInputsFromCheckedCase.length - 1].value === '') {
                newSSDInputsFromCheckedCase.pop();
            }
        }

        setSSDInputsFromCheckedCase(newSSDInputsFromCheckedCase);
        setRequestInProgress(false);
    };

    const getSSDBySSDNumber = (serialNumber) => {
        return dataProvider.getList('s_s_ds', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name' },
            filter: { serialNumber }
        });
    };

    const asociateSSDWithCase = (inputSSDNumber, callback) => {
        setRequestInProgress(true);
        getSSDBySSDNumber(inputSSDNumber).then(({ data }) => {
            if (Array.isArray(data) && data.length > 0) {
                dataProvider
                    .update('s_s_ds', {
                        id: data[0]['@id'],
                        data: { deliveryCase: checkedCaseDetails['@id'] }
                    })
                    .then(() => {
                        notify('SSD succesfully associated with the current case!');
                        refresh();
                        setRequestInProgress(false);
                        // eslint-disable-next-line no-unused-expressions
                        callback && callback();
                    });
            } else {
                notify(`No SSD found with Serial Number: ${inputSSDNumber}!`);
                setRequestInProgress(false);
            }
        });
    };

    const jumpToNextInput = (e) => {
        if (e.which === 13) {
            const nextTr = e.target.parentElement.parentElement.parentElement.parentElement.nextSibling;
            if (nextTr) {
                e.preventDefault();
                e.target.parentElement.parentElement.parentElement.parentElement.nextSibling
                    .querySelector('input')
                    .focus();
            } else {
                validateInputsBtnRef.current.focus();
            }
        }
    };

    const setFieldLinkBasedOnUserRole = () => {
        return isWerksMeisterUser(userRoles)
            ? 'show'
            : isEditLocksAccessible(userRoles);
    };

    return (
        <div style={{ position: 'relative' }}>
            {areCasesCheckInCheckOutButtonsEnabled(userRoles || []) && (
                <CheckinCheckout
                    entity="cases"
                    onCheckinClick={() => setShowModalCheckin(true)}
                    onCheckoutClick={() => setShowModalCheckout(true)}
                />
            )}
            <SSDSGanttChart />
            <List
                style={{ marginTop: '350px' }}
                className="centered-table"
                {...props}
                {...additionalProps}
                filter={{ ssds: ssdsFilter }}
                actions={
                    <CasesListActions
                        releaseFilter={releaseTimelineFilter}
                        clearReleaseFilter={() =>
                            setReleaseTimelineFilter({
                                filterProp: {},
                                releaseType: ''
                            })
                        }
                    />
                }
                filters={<PostFilter />}
            >
                <Datagrid>
                    <WorkflowCustomField sortable={false} source="workflowStatus" label="Status" />
                    <TextField source="serialNumber" label="serialNumber" />
                    <ReferenceField
                        link={setFieldLinkBasedOnUserRole()}
                        label="lock1"
                        source="lock1"
                        reference="locks"
                        sortBy="lock1.name"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    {false && (
                        <ReferenceField link label="lock2" source="lock2" reference="locks">
                            <TextField source="name" />
                        </ReferenceField>
                    )}
                    <ReferenceField
                        link
                        label="location"
                        source="location"
                        reference="locations"
                        sortBy="location.name"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    {isEditCasesAccessible(userRoles) && <EditButton />}
                </Datagrid>
            </List>
            <Dialog
                onClose={() => setShowModalCheckin(false)}
                aria-labelledby="simple-dialog-title"
                open={showModalCheckin}
            >
                <DialogTitle color="primary" id="simple-dialog-title" className="upload-dialog-title">
                    <HorizontalSplit style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                    Case {translate('cases.checkin')}
                </DialogTitle>
                <div
                    style={{
                        width: '500px',
                        padding: '30px',
                        position: 'relative'
                    }}
                >
                    <div className="flex-inline flex-left">
                        <MuiTextField
                            autoFocus
                            label={`Case ${translate('number')}`}
                            variant="outlined"
                            size="small"
                            value={checkinCheckoutInputSerialNumber}
                            onChange={(e) => setCheckinCheckoutInputSerialNumber(e.target.value)}
                        />
                        <MuiTextField
                            label={translate('comment')}
                            variant="outlined"
                            size="small"
                            value={checkinCheckoutInputComment}
                            onChange={(e) => setCheckinCheckoutInputComment(e.target.value)}
                        />
                    </div>
                    <div className="flex-inline flex-right" style={{ paddingTop: '10px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!checkinCheckoutInputSerialNumber}
                            onClick={doCheckin}
                        >
                            {translate('cases.checkin')}
                        </Button>
                        <Button onClick={() => setShowModalCheckin(false)} variant="contained">
                            {translate('ra.action.cancel')}
                        </Button>
                    </div>
                </div>
                {requestInProgress && (
                    <Backdrop className="spinning-loader-overlay" open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </Dialog>
            <Dialog
                onClose={() => setShowModalCheckout(false)}
                aria-labelledby="simple-dialog-title"
                open={showModalCheckout}
            >
                <DialogTitle color="primary" id="simple-dialog-title" className="upload-dialog-title">
                    <HorizontalSplit style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                    Case {translate('cases.checkout')}
                </DialogTitle>
                <div
                    style={{
                        width: '500px',
                        padding: '30px',
                        position: 'relative'
                    }}
                >
                    <div className="flex-inline flex-left">
                        <MuiTextField
                            autoFocus
                            label={`Case ${translate('number')}`}
                            variant="outlined"
                            size="small"
                            value={checkinCheckoutInputSerialNumber}
                            onChange={(e) => setCheckinCheckoutInputSerialNumber(e.target.value)}
                        />
                        <MuiTextField
                            label={translate('comment')}
                            variant="outlined"
                            size="small"
                            value={checkinCheckoutInputComment}
                            onChange={(e) => setCheckinCheckoutInputComment(e.target.value)}
                        />
                    </div>
                    <div className="flex-inline flex-right" style={{ paddingTop: '10px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!checkinCheckoutInputSerialNumber}
                            onClick={doCheckout}
                        >
                            {translate('cases.checkout')}
                        </Button>
                        <Button onClick={() => setShowModalCheckout(false)} variant="contained">
                            {translate('ra.action.cancel')}
                        </Button>
                    </div>
                </div>
                {requestInProgress && (
                    <Backdrop className="spinning-loader-overlay" open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </Dialog>
            <Dialog
                onClose={() => setShowModalLockCodes(false)}
                aria-labelledby="simple-dialog-title"
                open={showModalLockCodes}
            >
                <DialogTitle color="primary" id="simple-dialog-title" className="upload-dialog-title">
                    <HorizontalSplit style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                    Case Lock Codes
                </DialogTitle>
                <div
                    style={{
                        width: '500px',
                        padding: '30px',
                        position: 'relative'
                    }}
                >
                    <div
                        className="flex-inline"
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-around'
                        }}
                    >
                        <div>
                            <LockOpen style={{ verticalAlign: 'bottom' }} />
                            <strong>Lock 1:&nbsp;</strong>
                            <span style={{ color: 'red' }}>{caseLockCodes.lock1}</span>
                        </div>
                        {false && (
                            <div>
                                <LockOpen style={{ verticalAlign: 'bottom' }} />
                                <strong>Lock 2:&nbsp;</strong>
                                <span style={{ color: 'red' }}>{caseLockCodes.lock2}</span>
                            </div>
                        )}
                    </div>
                    <div className="flex-inline flex-right" style={{ paddingTop: '10px' }}>
                        <Button
                            onClick={() => {
                                setShowModalLockCodes(false);
                                setShowModalSSDConfirmInputs(true);
                            }}
                            variant="contained"
                        >
                            OK
                        </Button>
                    </div>
                </div>
                {requestInProgress && (
                    <Backdrop className="spinning-loader-overlay" open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </Dialog>
            <Dialog
                onClose={() => setShowModalSSDConfirmInputs(false)}
                aria-labelledby="simple-dialog-title"
                open={showModalSSDConfirmInputs}
            >
                <DialogTitle color="primary" id="simple-dialog-title" className="upload-dialog-title">
                    <HorizontalSplit style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                    {translate('caseSSDConfirmation')}
                </DialogTitle>
                <div
                    style={{
                        width: '500px',
                        padding: '30px',
                        position: 'relative'
                    }}
                >
                    <Button
                        ref={validateInputsBtnRef}
                        style={{ marginRight: '10px' }}
                        onClick={() => validateSSDInTheCase()}
                        variant="contained"
                    >
                        {translate('validateEntities')}
                    </Button>
                    <Button
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                            setSSDInputsFromCheckedCase([
                                ...SSDInputsFromCheckedCase,
                                {
                                    id: SSDInputsFromCheckedCase.length,
                                    value: '',
                                    valid: false,
                                    canBeAdded: false
                                }
                            ]);
                        }}
                        variant="contained"
                    >
                        {translate('addNewEntry')}
                    </Button>
                    <br />
                    <br />
                    <br />
                    <table style={{ width: '100%' }}>
                        {SSDInputsFromCheckedCase.map((input, idx) => (
                            <tr key={input.id}>
                                <td>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="standard-adornment-amount">{`SSD ${idx + 1}`}</InputLabel>
                                        <Input
                                            onKeyPress={jumpToNextInput}
                                            autoFocus={idx === 0}
                                            onBlur={() => validateSSDInTheCase()}
                                            id="standard-adornment-amount"
                                            value={input.value}
                                            onChange={(value) => updateSSDInputsFromCheckedCase(value, input.id)}
                                            startAdornment={<InputAdornment position="start">{'>'}</InputAdornment>}
                                            error={
                                                input.value && SSDInputsFromCheckedCase
                                                    .map(({ value }) => value)
                                                    .filter((value) => value === input.value)
                                                    .length > 1
                                            }
                                        />
                                        {input.value && SSDInputsFromCheckedCase
                                            .map(({ value }) => value)
                                            .filter((value) => value === input.value)
                                            .length > 1 && <FormHelperText error>{translate('ssdAlreadyAdded')}</FormHelperText>}
                                    </FormControl>
                                </td>
                                <td style={{ width: '20%' }}>
                                    {input.valid ? (
                                        <IconButton aria-label="add">
                                            <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                        </IconButton>
                                    ) : (
                                        <IconButton aria-label="add">
                                            <HighlightOffIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    )}
                                    {input.canBeAdded && (
                                        <IconButton
                                            aria-label="add"
                                            onClick={() => {
                                                asociateSSDWithCase(input.value, () => {
                                                    const newSSDInputsFromCheckedCase = [...SSDInputsFromCheckedCase];
                                                    newSSDInputsFromCheckedCase[idx].valid = true;
                                                    newSSDInputsFromCheckedCase[idx].canBeAdded = false;
                                                    setSSDInputsFromCheckedCase([
                                                        ...newSSDInputsFromCheckedCase,
                                                        {
                                                            id: SSDInputsFromCheckedCase.length,
                                                            value: '',
                                                            valid: false,
                                                            canBeAdded: false
                                                        }
                                                    ]);
                                                    getCheckedCaseDetails(false);
                                                });
                                            }}
                                        >
                                            <AddCircleOutlineIcon />
                                        </IconButton>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </table>
                    <br />
                    <br />
                    <br />
                    <Button
                        style={{ marginRight: '10px', float: 'right' }}
                        onClick={() => {
                            validateSSDInTheCase();
                            setShowModalSSDFinalStatusList(true);
                            setShowModalSSDConfirmInputs(false);
                        }}
                        variant="contained"
                        disabled={hasDuplicates(SSDInputsFromCheckedCase.map(({ value }) => value).filter((value) => value))}
                    >
                        {translate('next')}
                    </Button>
                </div>
                {requestInProgress && (
                    <Backdrop className="spinning-loader-overlay" open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </Dialog>
            <Dialog
                onClose={() => setShowModalSSDFinalStatusList(false)}
                aria-labelledby="simple-dialog-title"
                open={showModalSSDFinalStatusList}
            >
                <DialogTitle color="primary" id="simple-dialog-title" className="upload-dialog-title">
                    <HorizontalSplit style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                    {translate('checkedSSDsConfirmList')}
                </DialogTitle>
                <div
                    style={{
                        width: '500px',
                        padding: '30px',
                        position: 'relative'
                    }}
                >
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{translate('inTheSystem')}</TableCell>
                                    <TableCell>{translate('inRealCase')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {checkedCaseDetails.ssds &&
                                    checkedCaseDetails.ssds.map((ssd) => {
                                        const ssdInputRefference = SSDInputsFromCheckedCase.find(
                                            (input) => input.value === ssd.serialNumber
                                        );
                                        return (
                                            <TableRow>
                                                <TableCell>{ssd.serialNumber}</TableCell>
                                                <TableCell>
                                                    {ssdInputRefference && ssdInputRefference.valid ? (
                                                        <span>{translate('available')}</span>
                                                    ) : (
                                                        <span style={{ color: 'red' }}>{translate('missing')}</span>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <p>{translate('ifYouHaveMissingSSDs')}</p>
                    <br />
                    <Button
                        style={{ marginRight: '10px', float: 'right' }}
                        onClick={() => {
                            setShowModalCheckin(false);
                            setShowModalCheckout(false);
                            setShowModalLockCodes(false);
                            setShowModalSSDConfirmInputs(false);
                            setShowModalSSDFinalStatusList(false);
                            setSSDInputsFromCheckedCase([]);
                            setCheckedCaseDetails({});
                        }}
                        variant="contained"
                    >
                        Close
                    </Button>
                </div>
                {requestInProgress && (
                    <Backdrop className="spinning-loader-overlay" open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </Dialog>
        </div>
    );
};

export default CasesList;
