/* eslint-disable camelcase */
import React, { useCallback, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';

function RDB_SeasonList({ data, onSelectedRowsChange, clearSelected, loading }) {
    const [selectedRecord, setSelectedRecord] = useState(null);
    const columns = [
        {
            name: 'Name',
            selector: (row) => row.name
        }
    ];

    const translateReleaseAssetsData = (originalData) => {
        const translatedData = originalData.map((season, idx) => {
            let displayFrom = moment().format('YYYY-MM-DD');
            if (season.licenses && season.licenses.length > 0) {
                displayFrom = season.licenses[0].start;
            }
            const { slug } = season;
            return { name: season.name, resourceId: season.id, id: idx + 1, slug, displayFrom };
        });
        return translatedData;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onRowClicked = useCallback((row) => {
        if (row.id) {
            if (selectedRecord !== row.id) {
                setSelectedRecord(row.id);
                onSelectedRowsChange(row);
            } else {
                setSelectedRecord(null);
                onSelectedRowsChange(null);
            }
        }
    });

    useEffect(() => { setSelectedRecord(null); }, [clearSelected]);

    const conditionalRowStyles = [
        {
            when: (row) => row.id === selectedRecord,
            style: {
                backgroundColor: '#F9E79F',
                color: '#000',
                '&:hover': {
                    cursor: 'pointer'
                }
            }
        }
    ];
    return (
        <DataTable
            dense
            columns={columns}
            data={translateReleaseAssetsData(data)}
            paginationPerPage={5}
            progressPending={loading}
            conditionalRowStyles={conditionalRowStyles}
            onRowClicked={onRowClicked}
            highlightOnHover
            pointerOnHover
        />
    );
}

export default RDB_SeasonList;
