/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useRef } from 'react';
import { Button, useNotify, useRefresh } from 'react-admin';
import { CloudUpload, Close, Publish, Attachment } from '@material-ui/icons';
import { DialogTitle, Dialog, Chip, CircularProgress } from '@material-ui/core';

const FileUploadDiaglog = (props) => {
    const fileInputRef = useRef(null);
    const notify = useNotify();
    const refresh = useRefresh();
    const { onClose, open } = props;
    const [file, setFile] = useState('');
    const [loading, setLoading] = useState(false);

    const handleFileUpload = () => {
        const formData = new FormData();

        formData.append('file', file);
        setLoading(true);
        fetch(`${global.RA_API_ENTRYPOINT}/import_contentlists`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: formData
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.uploaded) {
                    setLoading(false);
                    onClose(() => {
                        fileInputRef.current.value = '';
                        notify('Content succesfully uploaded!');
                        refresh();
                    });
                } else {
                    setLoading(false);
                    notify(
                        response.errors ||
                            response['hydra:description'] ||
                            'Import could not be finished!'
                    );
                }
            });
    };

    const handleClose = () => {
        fileInputRef.current.value = '';
        onClose(() => {
            fileInputRef.current.value = '';
        });
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle
                color="primary"
                id="simple-dialog-title"
                className="upload-dialog-title"
            >
                <Attachment
                    style={{ verticalAlign: 'middle', marginRight: '10px' }}
                />
                Import Content List
            </DialogTitle>
            <div
                style={{
                    width: '500px',
                    padding: '30px',
                    position: 'relative'
                }}
            >
                <div style={{ display: 'flex', width: '100%' }}>
                    <Chip
                        label={(file && file.name) || 'No file selected...'}
                        onDelete={() => {
                            setFile('');
                            fileInputRef.current.value = '';
                        }}
                        color="primary"
                        variant="outlined"
                        style={{ width: '90%', marginRight: '10px' }}
                    />
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={handleFileChange}
                        hidden
                    />
                    <Button
                        onClick={() => {
                            fileInputRef.current.click();
                        }}
                        label="Pick a file"
                        component="label"
                        style={{ width: '200px' }}
                        variant="contained"
                    >
                        <Publish />
                    </Button>
                </div>
                <div
                    style={{
                        bottom: '30px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '40px'
                    }}
                >
                    {file && file.name && (
                        <Button
                            variant="contained"
                            onClick={handleFileUpload}
                            label="Import"
                            component="label"
                            style={{ marginRight: '10px' }}
                        >
                            {(loading && (
                                <CircularProgress
                                    size={15}
                                    style={{
                                        color: '#fff'
                                    }}
                                />
                            )) || <CloudUpload />}
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        onClick={() => onClose()}
                        label="Cancel"
                        component="label"
                    >
                        <Close />
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default FileUploadDiaglog;
