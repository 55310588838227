/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState } from 'react';
import {
    useEditController,
    Edit,
    TabbedForm,
    FormTab,
    useNotify,
    useRedirect,
    useMutation,
    Toolbar,
    SaveButton,
    DeleteButton,
    useRefresh
} from 'react-admin';
import {
    submitErrorsMutators,
    SubmitErrorsSpy
} from 'final-form-submit-errors';
import WorkflowTimeline from '../WorkflowTimeline';
import { mediaStatuses } from '../../constants';
import MediaInputElements from './MediaInputElements';
import BasicActionsToolbar from '../BasicActionsToolbar';
import '../entities.scss';

const stringEnum = {
    UPLOAD_API_PATH: '/media_objects',
    MEDIA_SUCCESS_MSG: 'Media succesfully updated!',
    FILE_UPLOAD_SUCCESS_MSG: 'File succesfully uploaded!'
};

const MediaEdit = (props) => {
    const { record } = useEditController(props);
    const [mediaFile, setMediaFile] = useState(null);
    const [mutate] = useMutation();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const updateTransition = () => {
        mutate(
            {
                type: 'update',
                resource: props.resource,
                payload: {
                    id: record.id,
                    data: {
                        ...record,
                        makeWorkflowTransition:
                            record.nextWorkflowTransitions[0]
                    }
                }
            },
            {
                mutationMode: 'pessimistic',
                onSuccess: () => {
                    notify('Transition changed', 'info', {}, true);
                    redirect('/media');
                },
                onFailure: (error) =>
                    notify(`Error: ${error.message}`, 'warning')
            }
        );
    };

    const customSaveWithFileUpload = async (values) => {
        try {
            const { data } = await mutate(
                {
                    type: 'update',
                    resource: props.resource,
                    payload: { id: values.id, data: values }
                },
                { mutationMode: 'pessimistic', returnPromise: true }
            );

            notify(stringEnum.MEDIA_SUCCESS_MSG);
            refresh();

            if (mediaFile) {
                const formData = new FormData();
                formData.append('file', mediaFile);
                formData.append('mediaId', data['@id']);
                fetch(
                    `${global.RA_API_ENTRYPOINT}${stringEnum.UPLOAD_API_PATH}`,
                    {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                'token'
                            )}`
                        },
                        body: formData
                    }
                )
                    .then((response) => {
                        if (response.status === 201) {
                            notify(stringEnum.FILE_UPLOAD_SUCCESS_MSG);
                            redirect('/media');
                        } else {
                            notify('File upload failed');
                        }
                    })
                    .catch((error) => {
                        notify('File upload failed: ', error);
                    });
            }
        } catch (error) {
            const submissionErrors = props.schemaAnalyzer.getSubmissionErrors(
                error
            );

            if (!submissionErrors) {
                notify(`Error: ${error.message}`);
                return {};
            }

            // workaround: API returns category as string, ui expects an object with property @id
            if (submissionErrors.type) {
                const type = { '@id': submissionErrors.type };
                submissionErrors.type = type;
            }

            return submissionErrors;
        }
    };

    const nextTransitionActionProp = {};
    if (record && record.workflowStatus === 'Error') {
        nextTransitionActionProp.nextTransitionAction = updateTransition;
        nextTransitionActionProp.nextTransitionActionDialogMessage =
            'Workflow status will be sent back in [Quality Check]. Are you sure?';
    }

    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            transform={(data) => ({
                ...data,
                type: data.type['@id']
            })}
            actions={<BasicActionsToolbar showList {...props} />}
        >
            <TabbedForm
                sanitizeEmptyValues={false}
                save={customSaveWithFileUpload}
                mutators={{
                    ...submitErrorsMutators
                }}
                toolbar={
                    <Toolbar {...props}>
                        <SaveButton />
                        <DeleteButton undoable={false} />
                    </Toolbar>
                }
            >
                <FormTab label="Edit Media">
                    <MediaInputElements
                        operation="EDIT"
                        onFileInputChange={setMediaFile}
                        mediaFile={mediaFile}
                    />
                </FormTab>
                <FormTab label="Workflow">
                    <div style={{ width: '100%' }}>
                        {record && (
                            <WorkflowTimeline
                                resource="media"
                                record={record}
                                currentStatus={record.workflowStatus}
                                allStatuses={mediaStatuses}
                                lastStatus={false}
                                {...nextTransitionActionProp}
                            />
                        )}
                    </div>
                </FormTab>
                <SubmitErrorsSpy />
            </TabbedForm>
        </Edit>
    );
};

export default MediaEdit;
