/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

const environmentWhitelistKeywords = ['integration', 'testing', 'stage', 'red', 'blue', 'yellow', 'green'];

function RDB_PageList({ data, onSelectedRowsChange, filters, loading, clearSelected }) {
    const [selectedRecord, setSelectedRecord] = useState(null);
    const previewPage = (row) => {
        if (global.RA_API_ENTRYPOINT) {
            const splittedByDot = global.RA_API_ENTRYPOINT.split('.');
            if (environmentWhitelistKeywords.includes(splittedByDot[1])) {
                const previewURL = `https://portal.${splittedByDot[1]}.db-ice-vod.endava.berlin/${row.slug}?display-date=${moment(row.validFrom).format('YYYY-MM-DD')}`;
                window.open(previewURL, '_blank');
            }
        }
    };
    const columns = [
        {
            id: 'name',
            name: 'Name',
            selector: (row) => row.name,
            sortable: true
        },
        {
            id: 'type',
            name: 'Type',
            selector: (row) => row.type,
            maxWidth: '30px',
            sortable: true
        },
        {
            id: 'displayFrom',
            name: 'Display from',
            selector: (row) => row.validFrom,
            format: (row) => (row.validFrom ? moment(row.validFrom).format('DD.MM.YYYY') : ''),
            sortable: true,
            maxWidth: '120px'
        },
        {
            id: 'displayTo',
            name: 'Display to',
            selector: (row) => row.validTo,
            format: (row) => (row.validTo ? moment(row.validTo).format('DD.MM.YYYY') : ''),
            sortable: true,
            maxWidth: '110px'
        },
        {
            id: 'preview',
            name: 'Preview',
            cell: (row) => {

                return row.name ? (
                    <IconButton aria-label="preview" size="small" style={{ color: 'green' }} onClick={() => previewPage(row)}>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                ) : '';
            },
            maxWidth: '30px'
        }
    ];

    const translateReleaseAssetsData = (originalData) => {
        let translatedData = originalData.map((asset, idx) => ({ ...asset, resourceId: asset['@id'].split('/')[3], id: idx + 1 }));

        if (filters.pageSearchKeyword.length > 0) {
            translatedData = translatedData.filter((asset) => asset.name.toLowerCase().includes(filters.pageSearchKeyword.toLowerCase()));
        }

        if (translatedData.length < 10) {
            const generateEmptyRecord = () => {
                const highestId = translatedData.reduce((acc, val) => {
                    if (val.id > acc) {
                        return val.id;
                    }
                    return acc;
                }, 0);
                return { id: highestId + 1, name: '', type: '', validFrom: '', validto: '', resourceId: '' };
            };
            const emptySlotsToFill = 10 - translatedData.length;
            for (let i = 0; i < emptySlotsToFill; i++) {
                translatedData.push(generateEmptyRecord());
            }
        }
        return translatedData;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onRowClicked = useCallback((row) => {
        if (row.resourceId) {
            if (selectedRecord !== row.id) {
                setSelectedRecord(row.id);
                onSelectedRowsChange(row);
            } else {
                setSelectedRecord(null);
                onSelectedRowsChange(null);
            }
        }
    });

    const conditionalRowStyles = [
        {
            when: (row) => row.id === selectedRecord,
            style: {
                backgroundColor: '#F9E79F',
                color: '#000',
                '&:hover': {
                    cursor: 'pointer'
                }
            }
        }
    ];

    useEffect(() => {
        setSelectedRecord(null);
    }, [clearSelected]);

    return (
        <DataTable
            dense
            columns={columns}
            data={translateReleaseAssetsData(data)}
            conditionalRowStyles={conditionalRowStyles}
            onRowClicked={onRowClicked}
            defaultSortFieldId="type"
            highlightOnHover
            pointerOnHover
            pagination
            progressPending={loading}
            progressComponent={<div className="loader-container"><CircularProgress /></div>}
        />
    );
}

export default RDB_PageList;
