import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select as MaterialSelect, FormControl, InputLabel } from '@material-ui/core';
import { generateLanguageTrackLabel } from 'utils/player';

const Select = ({ value, label, list, onChange }) => {
    return (
        list.length ? (
            <FormControl>
                <InputLabel>{label}</InputLabel>
                <MaterialSelect
                    value={generateLanguageTrackLabel(value)}
                    disableUnderline
                    onChange={(e) => onChange(e.target.value)}
                    renderValue={() => generateLanguageTrackLabel(value)}
                >
                    {list.map((listItem) => (
                        <MenuItem key={listItem.id} value={listItem}>
                            {generateLanguageTrackLabel(listItem)}
                        </MenuItem>
                ))}
                </MaterialSelect>
            </FormControl>
) : null
    );
};

Select.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            orientation: PropTypes.string,
            sortingField: PropTypes.string,
            text: PropTypes.string
        })
    ]).isRequired,
    label: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

export default Select;
