export const seconds2Date = (seconds = 0) => {
    const currentDateTime = new Date(0);
    currentDateTime.setHours(0);
    currentDateTime.setSeconds(seconds);
    return currentDateTime;
};

export const seconds2TimeString = (seconds = 0) => {
    return seconds2Date(seconds).toTimeString().split(' ')[0];
};

const extractTimeFromDate = (date = '') => {
    return new Date(date).toTimeString().split(' ')[0];
};

export const timeFromDateTime2Seconds = (dateTime) => dateTime.getSeconds() + dateTime.getMinutes() * 60 + dateTime.getHours() * 60 * 60;

export class MarkersCRUDInputValidator {
    constructor(targetedMarkType, name, startTime, endTime, maxAllowedTime) {
        this.targetedMarkType = targetedMarkType;
        this.name = name;
        this.startTime = startTime;
        this.endTime = endTime;
        this.maxAllowedTime = maxAllowedTime;
        this.maxAllowedTimeBeautified = extractTimeFromDate(maxAllowedTime);
        this.errorMessages = { startTimeErrorMsg: '', endTimeErrorMsg: '', nameErrorMessage: '' };
    }

    validateStartTimeIsLessThanMaxTime() {
        this.errorMessages.startTimeErrorMsg = '';

        if (this.startTime < this.maxAllowedTime) {
            return this;
        }

        this.errorMessages.startTimeErrorMsg = `Start time exceeds the max allowed time: ${this.maxAllowedTimeBeautified}`;
        return this;
    }

    validateEndTimeIsLessThanMaxTime() {
        this.errorMessages.endTimeErrorMsg = '';

        if (this.endTime < this.maxAllowedTime) {
            return this;
        }

        this.errorMessages.endTimeErrorMsg = `End time exceeds the max allowed time: ${this.maxAllowedTimeBeautified}`;
        return this;
    }

    validateStartTimeIsLessThanEndTime() {
        this.errorMessages.startTimeErrorMsg = '';
        if (this.startTime <= this.endTime) {
            return this;
        }

        this.errorMessages.startTimeErrorMsg = 'Start time has to be less than the end time';
        return this;
    }

    validateNameNotEmpty() {
        this.errorMessages.nameErrorMessage = '';

        if (this.name) {
            return this;
        }

        this.errorMessages.nameErrorMessage = 'Name cannot be empty';
        return this;
    }

    getValidationErrorMessages() {
        return this.errorMessages;
    }
}

export const runMarkersCrudValidations = (targetedMarkType, name, startTime, endTime, maxTime) => {
    return new MarkersCRUDInputValidator(targetedMarkType, name, startTime, endTime, maxTime)
        .validateStartTimeIsLessThanMaxTime()
        .validateEndTimeIsLessThanMaxTime()
        .validateStartTimeIsLessThanEndTime()
        .validateNameNotEmpty()
        .getValidationErrorMessages();
};

export const compareMarkersByStartTime = ({ timeStart: startTime1 }, { timeStart: startTime2 }) => {
    if (startTime1 < startTime2) {
        return -1;
    }
    if (startTime1 > startTime2) {
        return 1;
    }

    return 0;
};
