/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { useInput, Query, LinearProgress, Error } from 'react-admin';
import {
    List,
    ListItem,
    ListItemSecondaryAction,
    IconButton,
    Paper
} from '@material-ui/core';
import { useForm } from 'react-final-form';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SubentityEditForm from './SubentityEditForm';

function SubentityListForm(props) {
    const {
        input: { value }
    } = useInput(props);
    const form = useForm();
    const {
        edit: SubEntityEditModule,
        name: subentityResource,
        basePath
    } = props;
    const [currentRecord, setCurrentRecord] = React.useState(null);

    const records = value === '' ? [] : value;
    const removeElementFromList = (id) => {
        const newValues = [...value];
        const currentIndex = newValues.indexOf(id);
        if (currentIndex !== -1) {
            newValues.splice(currentIndex, 1);
        }
        form.change(props.subEntity, [...newValues]);
    };
    return (
        <Paper elevation={1} style={{ width: '70%', marginTop: '40px' }}>
            <List dense>
                {records.length > 0 ? (
                    records.map((el, idx) => (
                        <Query
                            key={el}
                            type="getOne"
                            resource={subentityResource}
                            payload={{ id: el }}
                        >
                            {({ data, loading, error }) => {
                                if (loading) {
                                    return <LinearProgress />;
                                }
                                if (error) {
                                    return <Error />;
                                }
                                return (
                                    <ListItem
                                        style={{
                                            borderBottom:
                                                idx === records.length - 1
                                                    ? 'none'
                                                    : '1px solid silver',
                                            padding: '10px 15px'
                                        }}
                                    >
                                        {data.content}
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                style={{ color: '#3385ff' }}
                                                onClick={() =>
                                                    setCurrentRecord(
                                                        data['@id']
                                                    )
                                                }
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                style={{ color: '#ff471a' }}
                                                edge="end"
                                                onClick={() =>
                                                    removeElementFromList(
                                                        data['@id']
                                                    )
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            }}
                        </Query>
                    ))
                ) : (
                    <ListItem>No data available</ListItem>
                )}
            </List>
            {currentRecord && (
                <SubentityEditForm
                    id={currentRecord}
                    onCloseCallback={() => setCurrentRecord(null)}
                    basePath={basePath}
                    subEntity={subentityResource}
                    onSuccessCallback={() => {
                        setCurrentRecord(null);
                    }}
                >
                    <SubEntityEditModule />
                </SubentityEditForm>
            )}
        </Paper>
    );
}

export default SubentityListForm;
