/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { cloneElement } from 'react';
import Chip from '@material-ui/core/Chip';
import {
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    sanitizeListRestProps, usePermissions
} from 'react-admin';
import { isCreateCasesAccessible } from '../../utils/user';

const SSDSListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        releaseFilter,
        clearReleaseFilter,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total
    } = useListContext();
    const { permissions: userRoles } = usePermissions();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {releaseFilter.releaseType && (
                <Chip
                    label={`Custom filter: ${releaseFilter.releaseType} release`}
                    onDelete={() => clearReleaseFilter()}
                    color="primary"
                    style={{ float: 'left' }}
                />
            )}
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button'
                })}
            {
                isCreateCasesAccessible(userRoles) && (
                    <CreateButton basePath={basePath} variant="outlined" style={{ marginLeft: '10px' }}/>
                )
            }
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
                variant="outlined"
                style={{ marginLeft: '10px' }}
            />
        </TopToolbar>
    );
};

export default SSDSListActions;
