/* eslint-disable react/jsx-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import {
    Create,
    TabbedForm,
    FormTab,
    useNotify,
    useRedirect,
    useMutation
} from 'react-admin';
import {
    submitErrorsMutators,
    SubmitErrorsSpy
} from 'final-form-submit-errors';
import BasicActionsToolbar from '../BasicActionsToolbar';
import metadata from '../Metadata';
import SubentityCreateForm from '../SubentityCreateForm';
import SubentityListForm from '../SubentityListForm';
import VideoInputElements from './VideoInputElements';
import '../entities.scss';

const VideosCreate = (props) => {
    const [mutate] = useMutation();
    const notify = useNotify();
    const redirect = useRedirect();
    // eslint-disable-next-line consistent-return
    const save = async (values) => {
        try {
            await mutate(
                {
                    type: 'create',
                    resource: props.resource,
                    payload: {
                        data: {
                            ...values,
                            enabled: Number(values.enabled),
                            animatedImage: values?.animatedImage?.['@id'],
                            staticImage: values?.staticImage?.['@id'],
                            category: values?.category?.['@id'],
                            smallImage: values?.smallImage?.['@id'],
                            media: values?.media?.['@id']
                        }
                    }
                },
                { returnPromise: true }
            );
            notify('Video was created !');
            redirect(`/${props.resource}`);
        } catch (error) {
            const submissionErrors = props.schemaAnalyzer.getSubmissionErrors(
                error
            );

            if (!submissionErrors) {
                notify(`Error: ${error.message}`);
                return {};
            }

            // workaround: API returns category as string, ui expects an object with property @id
            if (submissionErrors.category) {
                const category = { '@id': submissionErrors.category };
                submissionErrors.category = category;
            }

            return submissionErrors;
        }
    };
    return (
        <Create
            {...props}
            actions={<BasicActionsToolbar showList {...props} />}
            transform={(data) => ({
                ...data,
                category: data.category['@id'],
                smallImage: data.smallImage['@id'],
                media: data.media['@id']
            })}
        >
            <TabbedForm
                save={save}
                mutators={{
                    ...submitErrorsMutators
                }}
                sanitizeEmptyValues={false}
            >
                <FormTab label="Video">
                    <VideoInputElements {...props} />
                </FormTab>
                <FormTab label="Metadata">
                    <SubentityCreateForm source="metadata" {...metadata} />
                    <SubentityListForm source="metadata" {...metadata} />
                </FormTab>
                <SubmitErrorsSpy />
            </TabbedForm>
        </Create>
    );
};

export default VideosCreate;
