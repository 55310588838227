const createRequestHeaders = () => {
    return {
        'Content-Type': 'application/ld+json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };
};

const createGetMarkRequestConfig = (page, itemsPerPage, video) => {
    const url = new URL(`${window.RA_API_ENTRYPOINT}/marks`);
    url.search = new URLSearchParams({ page, itemsPerPage, video }).toString();
    const method = 'GET';
    const headers = createRequestHeaders();

    return { url, method, headers };
};

export const getMarks = async (page, itemsPerPage, videoId, onSuccess, onError) => {
    const { url, method, headers } = createGetMarkRequestConfig(page, itemsPerPage, videoId);
    const httpResponse = await fetch(url, { method, headers });
    if (!httpResponse.ok) {
        onError(httpResponse);
        return;
    }
    const httpResponseData = await httpResponse.json();
    onSuccess(httpResponseData);
};

const createPostMarkRequestConfig = () => {
    const url = new URL(`${window.RA_API_ENTRYPOINT}/marks`);
    const method = 'POST';
    const headers = createRequestHeaders();

    return { url, method, headers };
};

const beautifyUglyMarkId = (markUglyId) => {
    const fragmentedMarkId = markUglyId.split('/');
    return fragmentedMarkId[fragmentedMarkId.length - 1];
};

const createPutMarkRequestConfig = (markUglyId) => {
    const markId = beautifyUglyMarkId(markUglyId);
    const url = new URL(`${window.RA_API_ENTRYPOINT}/marks/${markId}`);
    const method = 'PUT';
    const headers = createRequestHeaders();

    return { url, method, headers };
};

const createDeleteMarkRequestConfig = (markUglyId) => {
    const markId = beautifyUglyMarkId(markUglyId);
    const url = new URL(`${window.RA_API_ENTRYPOINT}/marks/${markId}`);
    const method = 'DELETE';
    const headers = createRequestHeaders();

    return { url, method, headers };
};

export const createMark = async (markData, onSuccess, onError) => {
    const { url, method, headers } = createPostMarkRequestConfig();
    const httpResponse = await fetch(url, { method, headers, body: JSON.stringify(markData) });

    if (!httpResponse.ok) {
        onError(httpResponse);
        return;
    }

    onSuccess();
};

export const editMark = async (markId, markData, onSuccess, onError) => {
    const { url, method, headers } = createPutMarkRequestConfig(markId);
    const httpResponse = await fetch(url, { method, headers, body: JSON.stringify(markData) });

    if (!httpResponse.ok) {
        onError(httpResponse);
        return;
    }

    onSuccess();
};

export const deleteMark = async (markId, onSuccess, onError) => {
    const { url, method, headers } = createDeleteMarkRequestConfig(markId);
    const httpResponse = await fetch(url, { method, headers });

    if (!httpResponse.ok) {
        onError(httpResponse);
        return;
    }

    onSuccess();
};
