/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable max-len */
import React from 'react';
import './seasonsEdit.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';

const Episode = ({ pristine, index, setEpisodeData, data: { name, originalTitle, shortDescription, description, elipsis, episodeOrder, licenses, length } }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const handleSaveEpisode = () => {
        alert('TODO handleSaveEpisode');
    };
    const handleRollbackEpisode = () => {
        alert('TODO handleRollbackEpisode');
    };

    return (
        <div className="episode">
            <div className={classnames('episode__row', { episode__row__expanded: isExpanded })}>
                <div style={{ flexBasis: '10%' }}>
                    <Switch defaultChecked />
                </div>
                <div style={{ flexBasis: '10%' }}>{episodeOrder}</div>
                <div style={{ flexBasis: '30%' }}>{name}</div>
                <div style={{ flexBasis: '30%' }}>{originalTitle}</div>
                <div style={{ flexBasis: '20%' }}>-</div>
                <div style={{ flexBasis: '10%', textAlign: 'right' }}>
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                </div>
            </div>
            {isExpanded && (
                <div className="episode__body">
                    <div style={{ display: 'inline-flex', justifyContent: 'start', gap: '20px' }}>
                        <TextField
                            value={name}
                            onChange={({ target: { value } }) => setEpisodeData('name', value, index)}
                            style={{ flexBasis: '30%' }}
                            label="Episode Title"
                            variant="standard"
                        />
                        <TextField
                            value={originalTitle}
                            onChange={({ target: { value } }) => setEpisodeData('originalTitle', value, index)}
                            style={{ flexBasis: '30%' }}
                            label="Original Title"
                            variant="standard"
                        />
                        <TextField
                            type="number"
                            value={episodeOrder}
                            onChange={({ target: { value } }) => {
                                setEpisodeData('episodeOrder', value, index);
                            }}
                            style={{ flexBasis: '5%' }}
                            label="No"
                            variant="standard"
                        />
                        <div style={{ fontSize: '14px' }}>
                            <div style={{ color: 'gray', marginBottom: '10px' }}>Licenses</div>
                            <div>21.12.1986 - 21.12.1986</div>
                            <div>21.12.1986 - 21.12.1986</div>
                        </div>
                    </div>
                    <div style={{ display: 'inline-flex', gap: '15px' }}>
                        <TextField
                            value={description}
                            onChange={({ target: { value } }) => setEpisodeData('description', value, index)}
                            variant="outlined"
                            style={{ width: '100%' }}
                            label="Short description"
                            multiline
                            rows={4}
                            defaultValue=""
                        />
                        <TextField
                            value={elipsis}
                            onChange={({ target: { value } }) => setEpisodeData('elipsis', value, index)}
                            variant="outlined"
                            style={{ width: '100%' }}
                            label="Elipsis"
                            multiline
                            rows={4}
                            defaultValue=""
                        />
                    </div>
                    <div style={{ display: 'inline-flex', gap: '15px' }}>
                        <TextField label="Static Picture Source" variant="standard" disabled />
                        <TextField label="Video Source" variant="standard" disabled />
                        <TextField style={{ flexBasis: '5%' }} label="Length/min." variant="standard" />
                    </div>
                    <div style={{ display: 'inline-flex', gap: '10px', justifyContent: 'flex-end' }}>
                        <Button variant="outlined" onClick={handleRollbackEpisode} disabled={pristine}>
                            Cancel changes
                        </Button>
                        <Button variant="contained" onClick={handleSaveEpisode} color="primary" disableElevation disabled={pristine}>
                            Save
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Episode;
