const clpp = require('./cl.core.min');
require('./cl.mse.min');
require('./cl.dash.min');
require('./cl.hls.min');
require('./cl.onboard.min');
require('./cl.playlist.min');
require('./cl.thumbnails.min');
require('./cl.htmlcue.min');

export default clpp;
