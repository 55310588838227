/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Image } from '@material-ui/icons';

function ListThumbnailField({ record, source }) {
    const style = { fontSize: 40, color: 'lightblue' };
    const [thumb, setThumb] = useState(<Image style={style} />);
    React.useEffect(() => {
        if (record[source]) {
            const testImage = document.createElement('img');
            testImage.src = record[source].previewFullSource;
            testImage.onerror = () => {
                setThumb(<Image style={style} />);
            };

            testImage.onload = () => {
                setThumb(
                    <img
                        className="list-thumbnail"
                        src={record[source].previewFullSource}
                        alt=""
                    />
                );
            };
        } else {
            setThumb(<Image style={style} />);
        }
    }, [record]);

    return <div className="align-left">{thumb}</div>;
}

export default ListThumbnailField;
